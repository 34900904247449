import {useQuery} from '@apollo/react-hooks'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  GetSeatOptionsEventQuery,
  GetSeatOptionsEventQueryVariables
} from '../../../../__generated__/schema'
import {useEventSeats} from '../../../../hooks/eventSeats'
import {useGetClientLocaleTranslation} from '../../../../hooks/getClientLocaleTranslation'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {RenderOnData} from '../../../common'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {SecondaryHeader} from '../Header'
import {Content} from './Content'
import {GET_SEAT_OPTIONS_EVENT} from './graphql'

interface ISeatOptionsPageProps {
  eventId: number
}

export const SeatOptionsPage: React.FC<ISeatOptionsPageProps> = ({
  eventId
}: ISeatOptionsPageProps) => {
  const {t} = useTranslation()
  const [title, setTitle] = useState<string>(
    t<string>('Event with id {{eventId}}', {
      eventId
    })
  )
  const handleLeftActionClick = useCallback(() => window.close(), [])
  const {data, loading, error} = useQuery<
    GetSeatOptionsEventQuery,
    GetSeatOptionsEventQueryVariables
  >(GET_SEAT_OPTIONS_EVENT, {
    variables: {eventId}
  })
  const {
    data: eventSeatsData,
    loading: isEventSeatsLoading,
    interruptSubscription,
    resetSubscription
  } = useEventSeats({
    eventId,
    shouldCleanSelectedSeatsOnUnsubscribe: true
  })
  const {formatDateNumeric, formatTime} = useDateTimeFormatters()
  const getClientLocaleTranslation = useGetClientLocaleTranslation()
  useEffect(() => {
    if (data) {
      const startsAt = new Date(data.event.startsAt)
      setTitle(
        [
          formatDateNumeric(startsAt),
          formatTime(startsAt),
          getClientLocaleTranslation(data.event.names)
        ]
          .filter(Boolean)
          .join(' • ')
      )
    }
  }, [data, formatDateNumeric, formatTime, getClientLocaleTranslation])
  return (
    <PageWithHeaderTemplate
      header={
        <SecondaryHeader
          title={title}
          onLeftActionClick={handleLeftActionClick}
          isDatetimeEnabled
        />
      }
    >
      <RenderOnData<GetSeatOptionsEventQuery>
        data={data}
        loading={loading && isEventSeatsLoading}
        error={error}
        dataCondition={(data: GetSeatOptionsEventQuery) =>
          Boolean(data && data.event && eventSeatsData?.eventSeats)
        }
        errorMessage={t<string>('Could not load event data')}
      >
        {({event}) => (
          <Content
            event={event}
            eventSeats={eventSeatsData!.eventSeats}
            interruptSubscription={interruptSubscription}
            resetSubscription={resetSubscription}
          />
        )}
      </RenderOnData>
    </PageWithHeaderTemplate>
  )
}
