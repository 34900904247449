const getIncrementChar =
  (setOfCharacters: string) =>
  (char: string): string => {
    const index = setOfCharacters.indexOf(char)
    if (index === setOfCharacters.length - 1) {
      return setOfCharacters[0]
    }
    return setOfCharacters[index + 1]
  }

export const generateNextId = ({
  setOfCharacters,
  previous
}: {
  setOfCharacters: string
  previous: string
}) => {
  const incrementChar = getIncrementChar(setOfCharacters)

  const firstCharacterInSet = setOfCharacters[0]

  if (previous === '') {
    return firstCharacterInSet
  }

  const chars = previous.split('')
  let i = chars.length - 1

  while (i >= 0) {
    if (chars[i] !== setOfCharacters[setOfCharacters.length - 1]) {
      chars[i] = incrementChar(chars[i])
      return chars.join('')
    } else {
      chars[i] = firstCharacterInSet
      i--
    }
  }

  return firstCharacterInSet + chars.join('')
}

export const isBiggerSeatGroupId = (idA: string, idB: string): number => {
  if (idA.length !== idB.length) {
    return idA.length - idB.length
  }
  if (idA === idB) {
    return 0
  }
  return idA > idB ? 1 : -1
}

const getFunctions = (characters: string) => {
  let currentId: string = ''
  return {
    resetSeatGroupGenerator: () => {
      currentId = ''
    },
    restoreSeatGroupGenerator: (values: string[]) => {
      currentId = values.sort(isBiggerSeatGroupId)[values.length - 1]
    },
    getSeatGroupCompressedId: () => {
      const nextId = generateNextId({
        setOfCharacters: characters,
        previous: currentId
      })
      currentId = nextId
      return nextId
    }
  }
}

export const {
  resetSeatGroupGenerator,
  restoreSeatGroupGenerator,
  getSeatGroupCompressedId
} = getFunctions('ABCDEFGHIJKLMNOPQRSTUVWXYZ')
