import {CalendarApi} from '@fullcalendar/core'

import {useEventCalendarDateProperties} from '../context/eventCalendarDateProperties'

export const useDateState = (api: CalendarApi | null) => {
  const {selectedDate, setSelectedDate} = useEventCalendarDateProperties()

  const changeDate = (date: Date) => {
    if (!api) return
    setSelectedDate(date)
    api.gotoDate(date)
  }

  const onNext = () => {
    if (!api) return
    api.next()
    setSelectedDate(api.getDate())
  }

  const onPrev = () => {
    if (!api) return
    api.prev()
    setSelectedDate(api.getDate())
  }

  const onToday = () => {
    if (!api) return
    api.today()
    setSelectedDate(api.getDate())
  }

  return {
    selectedDate,
    changeDate,
    onNext,
    onPrev,
    onToday
  }
}
