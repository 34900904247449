import AddIcon from '@mui/icons-material/Add'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import EditIcon from '@mui/icons-material/Edit'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {
  Box,
  Button,
  Chip,
  IconButton,
  Paper,
  Tooltip as MuiTooltip,
  tooltipClasses,
  TooltipProps,
  Typography
} from '@mui/material'
import {styled} from '@mui/system'
import {gridClasses} from '@mui/x-data-grid'
import {
  GRID_REORDER_COL_DEF,
  GridColDef,
  GridEditInputCell,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowOrderChangeParams,
  GridValueSetterParams
} from '@mui/x-data-grid-pro'
import {clsx} from 'clsx'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  AdmissionRateQuery,
  AdmissionRateState,
  ClientVatRegistered,
  ExistingCountryCode,
  NarrowAdmissionTypeFieldsFragment,
  PermissionCode
} from '../../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../../hooks/state'
import {useEnsurePermissions, useUserInfo} from '../../../../../../utils/auth'
import {Tooltip} from '../../../../../common'
import {getIcon} from '../../../../../common/admissionTypeIcon'
import {ColorBox} from '../../../../../common/ColorBox'
import {
  DataGridTable,
  useEffectiveClientPriceFormatter,
  usePercentageFormatter,
  useSingleClickEditing
} from '../../../../../common/DataGridTable'
import {IconBox} from '../../../../../common/IconBox'
import {COLOR_CONF} from '../../../../../constants'
import {useCountryVatRate} from '../../../graphql'
import {
  useUpdateAdmissionRateAssignments,
  useUpdateDraftAdmissionRateAssignments
} from '../../graphql'
import {AddAdmissionTypeDialog} from './AddAdmissionTypeDialog'
import {AssignAccessZoneDrawer} from './AssignAccessZoneDrawer'
import {
  replaceCommaWithDot,
  transformAdmissionTypeAssignmentsToAssignmentInputs,
  transformAdmissionTypesToAssignmentInputs,
  transformDraftAdmissionTypeAssignmentsToAssignmentInputs,
  useIsNonNegativeNumber,
  useIsPositiveInteger
} from './utils'

const StyledTooltip = styled(({className, ...props}: TooltipProps) => (
  <MuiTooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  }
}))

const NameRenderer = ({
  admissionTypeAssignment
}: {
  admissionTypeAssignment: AdmissionRateQuery['admissionRate']['admissionTypesAssignments'][number]
}) => (
  <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
    {admissionTypeAssignment.admissionType.icon ? (
      <IconBox
        Icon={getIcon(admissionTypeAssignment.admissionType.icon)}
        hexColor={admissionTypeAssignment.admissionType.color}
        hideBackground
        sx={{width: 24, height: 24}}
        iconSx={{width: 24, height: 24}}
      />
    ) : (
      <ColorBox hexColor={admissionTypeAssignment.admissionType.color} />
    )}
    <Typography>{admissionTypeAssignment.admissionType.name}</Typography>
  </Box>
)

const DeleteCellRenderer = ({
  admissionType,
  admissionRateId,
  admissionRateAssignments
}: {
  admissionType: NarrowAdmissionTypeFieldsFragment
  admissionRateId: number
  admissionRateAssignments: AdmissionRateQuery['admissionRate']['admissionTypesAssignments']
}) => {
  const {t} = useTranslation()
  const updateDraftAdmissionRateAssignments =
    useUpdateDraftAdmissionRateAssignments()
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const filteredAdmissionTypeAssignments = admissionRateAssignments.filter(
    ({admissionTypeId}) => admissionTypeId !== admissionType.id
  )
  const handleButtonClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await updateDraftAdmissionRateAssignments({
        admissionRateId,
        assignmentInputs: transformAdmissionTypesToAssignmentInputs(
          filteredAdmissionTypeAssignments.map(
            ({admissionType}) => admissionType
          ),
          admissionRateAssignments
        )
      })
    } catch (error) {
      defaultErrorHandler(error, t('Error while updating draft admission rate'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    admissionRateAssignments,
    admissionRateId,
    defaultErrorHandler,
    filteredAdmissionTypeAssignments,
    setShowBackdrop,
    t,
    updateDraftAdmissionRateAssignments
  ])
  return (
    <Tooltip title={t('Remove')}>
      <IconButton size="small" color="primary" onClick={handleButtonClick}>
        <RemoveCircleOutlineIcon />
      </IconButton>
    </Tooltip>
  )
}

const AccessZonesCellRenderer = ({
  accessZonesLength,
  onEditClick
}: {
  accessZonesLength: number
  onEditClick: () => void
}) => (
  <Chip
    label={accessZonesLength}
    onClick={onEditClick}
    onDelete={onEditClick}
    deleteIcon={<EditIcon />}
  />
)

interface IAdmissionsProps {
  admissionRate: AdmissionRateQuery['admissionRate']
  admissionRateAssignments: AdmissionRateQuery['admissionRate']['admissionTypesAssignments']
  setAdmissionRateAssignments: React.Dispatch<
    React.SetStateAction<
      AdmissionRateQuery['admissionRate']['admissionTypesAssignments']
    >
  >
  refetch: () => void
}

export const Admissions: React.FC<IAdmissionsProps> = ({
  admissionRate,
  admissionRateAssignments,
  setAdmissionRateAssignments,
  refetch
}: IAdmissionsProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const updateDraftAdmissionRateAssignments =
    useUpdateDraftAdmissionRateAssignments()
  const updateAdmissionRateAssignments = useUpdateAdmissionRateAssignments()
  const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
    useMutationAssistanceHooks()
  const {effectiveClient} = useUserInfo()
  const [selectedAdmissionTypeId, setSelectedAdmissionTypeId] =
    useState<number | undefined>(undefined)
  const {
    data: countryVatRateData,
    error: countryVatRateError,
    loading: isCountryVatRateLoading
  } = useCountryVatRate(effectiveClient?.countryCode || ExistingCountryCode.Sk)
  useEffect(
    () => setAdmissionRateAssignments(admissionRate.admissionTypesAssignments),
    [admissionRate.admissionTypesAssignments, setAdmissionRateAssignments]
  )
  const singleClickEditing = useSingleClickEditing()
  const isNonNegativeNumber = useIsNonNegativeNumber()
  const isPositiveInteger = useIsPositiveInteger()
  const percentageFormatter = usePercentageFormatter(0)
  const effectiveClientPriceFormatter = useEffectiveClientPriceFormatter()
  const {
    state: isAddAdmissionTypeDialogOpen,
    setTrue: openAddAdmissionTypeDialog,
    setFalse: closeAddAdmissionTypeDialog
  } = useBooleanState(false)
  const isColumnEditable = admissionRate.state === AdmissionRateState.Draft
  const isVatRegistered =
    effectiveClient?.VATRegistered !== ClientVatRegistered.None
  const handleAddButtonClick = useCallback(
    async (admissionTypes: NarrowAdmissionTypeFieldsFragment[]) => {
      try {
        setShowBackdrop(true)
        await updateDraftAdmissionRateAssignments({
          admissionRateId: admissionRate.id,
          assignmentInputs: transformAdmissionTypesToAssignmentInputs(
            admissionTypes,
            admissionRateAssignments,
            effectiveClient?.ticketDefaultVatRate
          )
        })
        refetch()
      } catch (error) {
        defaultErrorHandler(
          error,
          t('Error while updating draft admission rate')
        )
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      admissionRate.id,
      admissionRateAssignments,
      defaultErrorHandler,
      effectiveClient?.ticketDefaultVatRate,
      refetch,
      setShowBackdrop,
      t,
      updateDraftAdmissionRateAssignments
    ]
  )
  useEffect(
    () =>
      isCountryVatRateLoading ? setShowBackdrop(true) : setShowBackdrop(false),
    [isCountryVatRateLoading, setShowBackdrop]
  )
  useEffect(() => {
    if (countryVatRateError) {
      defaultErrorHandler(
        countryVatRateError,
        t('Error while loading country VAT rates')
      )
    }
  }, [countryVatRateError, defaultErrorHandler, t])
  const {id: admissionRateId} = admissionRate
  const handleFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) =>
      e.target.select(),
    []
  )
  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...GRID_REORDER_COL_DEF,
        width: 40
      },
      {
        headerName: t('Name'),
        field: 'name',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <NameRenderer admissionTypeAssignment={params.row} />
        },
        minWidth: 350,
        editable: false,
        sortable: false
      },
      {
        headerName: t('Price'),
        field: 'retailPrice',
        editable: isColumnEditable,
        align: 'right',
        headerAlign: 'right',
        minWidth: 100,
        valueSetter: function setter(params: GridValueSetterParams) {
          return {
            ...params.row,
            retailPrice: parseFloat(
              replaceCommaWithDot(String(params.value))
            ).toFixed(2)
          }
        },
        preProcessEditCellProps: function processor(
          params: GridPreProcessEditCellProps
        ) {
          const error = isNonNegativeNumber(params.props.value)
          return {...params.props, errormessage: error, error: !!error}
        },
        renderEditCell: function renderer(params: GridRenderEditCellParams) {
          return (
            <StyledTooltip open={params.error} title={params.errormessage}>
              <span>
                <GridEditInputCell
                  {...params}
                  onFocus={handleFocus}
                  sx={{'& .MuiInputBase-input': {textAlign: 'right'}}}
                />
              </span>
            </StyledTooltip>
          )
        },
        valueFormatter: (params) =>
          params.id
            ? params.api.getCellMode(params.id, params.field) === 'view'
              ? effectiveClientPriceFormatter(params)
              : undefined
            : undefined,
        sortable: false,
        cellClassName: () =>
          admissionRate.state !== AdmissionRateState.Draft
            ? clsx('disabled')
            : ''
      },
      {
        headerName: t('VAT rate'),
        field: 'retailVatRate',
        editable: isColumnEditable,
        align: 'right',
        headerAlign: 'right',
        type: 'singleSelect',
        valueOptions: countryVatRateData?.countryVatRates.map((vat) => vat),
        valueFormatter: percentageFormatter,
        minWidth: 100,
        sortable: false,
        cellClassName: () =>
          admissionRate.state !== AdmissionRateState.Draft
            ? clsx('disabled')
            : ''
      },
      {
        headerName: t('Price'),
        field: 'eCommercePrice',
        editable: isColumnEditable,
        align: 'right',
        headerAlign: 'right',
        minWidth: 100,
        sortable: false,
        valueSetter: function setter(params: GridValueSetterParams) {
          return {
            ...params.row,
            eCommercePrice: parseFloat(
              replaceCommaWithDot(String(params.value))
            ).toFixed(2)
          }
        },
        preProcessEditCellProps: function processor(
          params: GridPreProcessEditCellProps
        ) {
          const error = isNonNegativeNumber(params.props.value)
          return {...params.props, errormessage: error, error: !!error}
        },
        renderEditCell: function renderer(params: GridRenderEditCellParams) {
          return (
            <StyledTooltip open={params.error} title={params.errormessage}>
              <span>
                <GridEditInputCell
                  {...params}
                  onFocus={handleFocus}
                  sx={{'& .MuiInputBase-input': {textAlign: 'right'}}}
                />
              </span>
            </StyledTooltip>
          )
        },
        valueFormatter: (params) =>
          params.id
            ? params.api.getCellMode(params.id, params.field) === 'view'
              ? effectiveClientPriceFormatter(params)
              : undefined
            : undefined,
        cellClassName: () =>
          admissionRate.state !== AdmissionRateState.Draft
            ? clsx('disabled')
            : ''
      },
      {
        headerName: t('VAT rate'),
        field: 'eCommerceVatRate',
        editable: isColumnEditable,
        type: 'singleSelect',
        align: 'right',
        headerAlign: 'right',
        valueOptions: countryVatRateData?.countryVatRates.map((vat) => vat),
        valueFormatter: percentageFormatter,
        minWidth: 100,
        sortable: false,
        cellClassName: () =>
          admissionRate.state !== AdmissionRateState.Draft
            ? clsx('disabled')
            : ''
      },
      {
        headerName: t('Retail'),
        field: 'hasEnabledDiscountsOnRetail',
        editable: true,
        type: 'boolean',
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('Ecommerce'),
        field: 'hasEnabledDiscountsOnECommerce',
        editable: true,
        type: 'boolean',
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('Retail'),
        field: 'enabledOnRetail',
        editable: true,
        type: 'boolean',
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('Ecommerce'),
        field: 'enabledOnECommerce',
        editable: true,
        type: 'boolean',
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('Attendees'),
        field: 'attendees',
        editable: false,
        align: 'right',
        headerAlign: 'right',
        valueGetter: (params) => params.row.admissionType.capacityDecreaseCount,
        minWidth: 100,
        sortable: false,
        cellClassName: () => clsx('disabled')
      },
      {
        headerName: t('Initial quantity'),
        field: 'initialQuantity',
        editable: false,
        align: 'right',
        headerAlign: 'right',
        valueGetter: (params) => params.row.admissionType.startingQuantity,
        minWidth: 150,
        sortable: false,
        cellClassName: () => clsx('disabled')
      },
      {
        headerName: t('Limit per time slot'),
        field: 'limitPerTimeSlot',
        editable: true,
        align: 'right',
        headerAlign: 'right',
        minWidth: 150,
        sortable: false,
        valueSetter: function setter(params: GridValueSetterParams) {
          return {
            ...params.row,
            limitPerTimeSlot: params.value
              ? parseInt(String(params.value), 10)
              : null
          }
        },
        preProcessEditCellProps: function processor(
          params: GridPreProcessEditCellProps
        ) {
          const error = params.props.value
            ? isPositiveInteger(params.props.value)
            : null
          return {...params.props, errormessage: error, error: !!error}
        },
        renderEditCell: function renderer(params: GridRenderEditCellParams) {
          return (
            <StyledTooltip open={params.error} title={params.errormessage}>
              <span>
                <GridEditInputCell
                  {...params}
                  onFocus={handleFocus}
                  sx={{'& .MuiInputBase-input': {textAlign: 'right'}}}
                />
              </span>
            </StyledTooltip>
          )
        }
      },
      {
        headerName: '',
        field: 'triggerCheckNotification',
        editable: true,
        type: 'boolean',
        minWidth: 100,
        disableColumnMenu: true,
        sortable: false
      },
      {
        headerName: '',
        field: 'id',
        editable: false,
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <DeleteCellRenderer
              admissionType={params.row.admissionType}
              admissionRateId={admissionRateId}
              admissionRateAssignments={admissionRateAssignments}
            />
          )
        },
        disableColumnMenu: true,
        width: 32,
        sortable: false
      },
      {
        headerName: t('Access zones'),
        field: 'accessZones',
        editable: false,
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <AccessZonesCellRenderer
              accessZonesLength={
                params.value.map(({id}: {id: number}) => id).length
              }
              onEditClick={() => setSelectedAdmissionTypeId(params.row.id)}
            />
          )
        },
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        minWidth: 130,
        sortable: false
      }
    ],
    [
      admissionRate.state,
      admissionRateAssignments,
      admissionRateId,
      countryVatRateData?.countryVatRates,
      effectiveClientPriceFormatter,
      handleFocus,
      isColumnEditable,
      isNonNegativeNumber,
      isPositiveInteger,
      percentageFormatter,
      t
    ]
  )
  const handleRowOrderChange = useCallback(
    async (params: GridRowOrderChangeParams) => {
      const rowsClone = [...admissionRateAssignments]
      const row = rowsClone.splice(params.oldIndex, 1)[0]
      rowsClone.splice(params.targetIndex, 0, row)
      try {
        setShowBackdrop(true)
        if (admissionRate.state === AdmissionRateState.Draft) {
          await updateDraftAdmissionRateAssignments({
            admissionRateId: admissionRate.id,
            assignmentInputs:
              transformDraftAdmissionTypeAssignmentsToAssignmentInputs(
                rowsClone
              )
          })
        } else {
          await updateAdmissionRateAssignments({
            admissionRateId: admissionRate.id,
            assignmentInputs:
              transformAdmissionTypeAssignmentsToAssignmentInputs(rowsClone)
          })
        }
        addInfoNotification(t('Admissions updated'))
      } catch (error) {
        defaultErrorHandler(error, 'Error while updating draft admission rate')
      } finally {
        setShowBackdrop(false)
      }
      setAdmissionRateAssignments(rowsClone)
    },
    [
      addInfoNotification,
      admissionRate.id,
      admissionRate.state,
      admissionRateAssignments,
      defaultErrorHandler,
      setAdmissionRateAssignments,
      setShowBackdrop,
      t,
      updateAdmissionRateAssignments,
      updateDraftAdmissionRateAssignments
    ]
  )
  const handleRowUpdate = useCallback(
    (newRow) => {
      setAdmissionRateAssignments((admissionRateAssignments) =>
        admissionRateAssignments.map((admissionRateAssignment) =>
          admissionRateAssignment.id === newRow.id
            ? newRow
            : admissionRateAssignment
        )
      )
      return newRow
    },
    [setAdmissionRateAssignments]
  )
  return (
    <>
      <Box
        sx={{
          '& .disabled': {
            color: 'text.disabled'
          }
        }}
      >
        <Box
          sx={{
            pb: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="subtitle1">{t('Admissions')}</Typography>
          {P([
            PermissionCode.ReadAdmissionTypes,
            PermissionCode.UpdateDraftAdmissionRateAssignments
          ]) &&
            admissionRate.state === AdmissionRateState.Draft && (
              <Button
                startIcon={<AddIcon />}
                variant="text"
                color="primary"
                onClick={openAddAdmissionTypeDialog}
              >
                {t('Add')}
              </Button>
            )}
        </Box>
        <Paper variant="outlined" sx={{pt: 1.5}}>
          <Box sx={{px: 3, pb: 2}}>
            <Typography variant="subtitle2">
              {t('Manage admissions order and other setting below.')}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {t(
                'You can edit price, add new or remove admissions only if admission rate is in draft state.'
              )}
            </Typography>
          </Box>
          <DataGridTable
            columns={columns}
            rows={admissionRate.admissionTypesAssignments}
            processRowUpdate={handleRowUpdate}
            pagination={false}
            initialState={{
              pinnedColumns: {left: ['__reorder__', 'name'], right: ['id']}
            }}
            {...singleClickEditing}
            rowReordering
            onRowOrderChange={handleRowOrderChange}
            disableColumnMenu
            disableRowSelectionOnClick
            slots={{rowReorderIcon: DragHandleIcon}}
            columnVisibilityModel={{
              id: admissionRate.state === AdmissionRateState.Draft,
              retailVatRate: isVatRegistered,
              eCommerceVatRate: isVatRegistered
            }}
            experimentalFeatures={{columnGrouping: true}}
            columnGroupingModel={[
              {
                groupId: 'retail',
                headerName: t('Retail'),
                children: [{field: 'retailPrice'}],
                headerAlign: 'right'
              },
              {
                groupId: 'eCommerce',
                headerName: t('Ecommerce'),
                children: [{field: 'eCommercePrice'}],
                headerAlign: 'right'
              },
              {
                groupId: 'discounts',
                headerName: t('Discounts'),
                children: [
                  {field: 'hasEnabledDiscountsOnRetail'},
                  {field: 'hasEnabledDiscountsOnECommerce'}
                ]
              },
              {
                groupId: 'enabled',
                headerName: t('Enabled'),
                children: [
                  {field: 'enabledOnRetail'},
                  {field: 'enabledOnECommerce'}
                ]
              },
              {
                groupId: 'limitations',
                headerName: t('Limitations'),
                children: [{field: 'attendees'}],
                headerAlign: 'right'
              },
              {
                groupId: 'notifyUsher',
                headerName: t('Notify usher'),
                children: [{field: 'triggerCheckNotification'}]
              }
            ]}
            hideFooter
            autoHeight
            sx={{
              border: 'none',
              borderRadius: 0,
              [`& .${gridClasses.withBorderColor}`]: {
                borderColor: 'divider'
              },
              [`& .${gridClasses.columnSeparator}`]: {
                color: 'divider'
              },
              [`.${gridClasses.main}`]: {
                overflow: 'unset'
              },
              [`.${gridClasses.columnHeaders}`]: {
                position: 'sticky',
                top: 0,
                backgroundColor: 'background.paper',
                zIndex: 1
              },
              [`&& .${gridClasses['cell--editable']}`]: {
                backgroundColor: COLOR_CONF.BLUE.background
              }
            }}
            columnHeaderHeight={32}
          />
        </Paper>
      </Box>
      {isAddAdmissionTypeDialogOpen && (
        <AddAdmissionTypeDialog
          onClose={closeAddAdmissionTypeDialog}
          assignedAdmissionTypes={admissionRate.admissionTypesAssignments.map(
            ({admissionType}) => admissionType
          )}
          onAddButtonClick={handleAddButtonClick}
        />
      )}
      <AssignAccessZoneDrawer
        isOpen={Boolean(selectedAdmissionTypeId)}
        onClose={() => setSelectedAdmissionTypeId(undefined)}
        assignedAccessZoneIds={
          admissionRateAssignments
            .find(({id}) => id === selectedAdmissionTypeId)
            ?.accessZones.map(({id}) => id) || []
        }
        admissionTypeToRateId={selectedAdmissionTypeId}
        refetch={refetch}
      />
    </>
  )
}
