import {GridColDef} from '@mui/x-data-grid-pro'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import {every, isNil, isNull, omit} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  AggregatedEventStatisticsByItemPriceQuery,
  DiscountType,
  LeadPropertiesFragment
} from '../../../__generated__/schema'
import {useFormatDiscountValue} from '../../../hooks/formatDiscountValue'
import {useUserInfo} from '../../../utils/auth'
import {useDateTimeFormatters} from '../../../utils/formatting'
import {useLocale} from '../../context/locale'
import {robotoMediumPdfFont, robotoRegularPdfFont} from './pdf-font'

export const isLeadDataPropertiesFragmentEmpty = (
  leadData?: LeadPropertiesFragment['data']
) => leadData && every(omit(leadData, '__typename'), (data) => isNull(data))

const useFormatDecimal = (fractionDigits: number = 2) => {
  const {localeCode} = useLocale()
  return (value: any) =>
    !isNil(value)
      ? parseFloat(value).toLocaleString(localeCode, {
          minimumFractionDigits: fractionDigits
        })
      : ''
}

const useDiscountValueFormatter = () => {
  const formatDiscountValue = useFormatDiscountValue(true)
  return (value: any, discountType?: DiscountType) =>
    value && discountType
      ? formatDiscountValue({value, type: discountType})
      : ''
}

export const useDownloadPdf = () => {
  const {t} = useTranslation()
  const {formatDateTime} = useDateTimeFormatters()
  const formatDecimal2Digits = useFormatDecimal()
  const formatDecimal0Digits = useFormatDecimal(0)
  const discountValueFormatter = useDiscountValueFormatter()
  const {user} = useUserInfo()
  const name = [user?.lastName, user?.firstName].join(' ')
  return ({
    data,
    columns,
    subtitle,
    iFrameRef
  }: {
    data: AggregatedEventStatisticsByItemPriceQuery
    columns: GridColDef[]
    subtitle: string
    iFrameRef?: React.MutableRefObject<HTMLIFrameElement | null>
  }) => {
    const {venue, division} = data.aggregatedEventStatisticsByItemPrice
    // eslint-disable-next-line new-cap
    const doc = new jsPDF('l')
    doc.addFileToVFS('Roboto-Medium.ttf', robotoMediumPdfFont)
    doc.addFont('Roboto-Medium.ttf', 'RobotoMedium', 'Medium')
    doc.addFileToVFS('Roboto-Regular.ttf', robotoRegularPdfFont)
    doc.addFont('Roboto-Regular.ttf', 'RobotoRegular', 'Regular')
    doc.setFont('RobotoMedium', 'Medium')
    doc.setFontSize(16)
    doc.text(t('Event sales report'), 14, 10)
    doc.setFontSize(10)
    doc.setFont('RobotoRegular', 'Regular')
    const printText = [
      t('Print: {{name}}', {name}),
      formatDateTime(new Date())
    ].join(' • ')
    doc.text(
      printText,
      doc.internal.pageSize.getWidth() - doc.getTextWidth(printText) - 14,
      10
    )
    doc.setFontSize(14)
    doc.setFont('RobotoMedium', 'Medium')
    doc.text(subtitle, 14, 17)
    doc.setFontSize(10)
    doc.setFont('RobotoRegular', 'Regular')
    doc.text(
      [
        t('Venue'),
        [
          venue.name,
          venue.address.complex,
          venue.address.street,
          venue.address.postalCode,
          venue.address.town,
          venue.address.country
        ]
          .filter(Boolean)
          .join(' • ')
      ].join(': '),
      14,
      23
    )
    doc.text(
      [
        t('Division'),
        [
          division.name,
          division.address.complex,
          division.address.street,
          division.address.postalCode,
          division.address.town,
          division.address.country
        ]
          .filter(Boolean)
          .join(' • ')
      ].join(': '),
      14,
      29
    )
    doc.text(
      [
        t('Company'),
        [
          division.client.name,
          division.client.legalAddress.complex,
          division.client.legalAddress.street,
          division.client.legalAddress.postalCode,
          division.client.legalAddress.town,
          division.client.legalAddress.country
        ]
          .filter(Boolean)
          .join(' • ')
      ].join(': '),
      14,
      35
    )
    autoTable(doc, {
      startY: 40,
      theme: 'grid',
      styles: {font: 'RobotoMedium', fontSize: 8},
      headStyles: {
        fillColor: [246, 246, 246],
        textColor: [0, 0, 0]
      },
      footStyles: {fillColor: [246, 246, 246], textColor: [0, 0, 0]},
      bodyStyles: {font: 'RobotoRegular'},
      head: [
        [
          {content: '', colSpan: 1},
          {content: t('Ticket'), colSpan: 4},
          {content: t('Sales'), colSpan: 4},
          {content: t('Refunds'), colSpan: 4},
          {content: t('Summary'), colSpan: 2}
        ],
        [
          {content: '', colSpan: 2},
          {content: t('Discount'), colSpan: 3},
          {content: t('Retail'), colSpan: 2},
          {content: t('Ecommerce'), colSpan: 2},
          {content: t('Retail'), colSpan: 2},
          {content: t('Ecommerce'), colSpan: 2}
        ],
        columns.map(({headerName, headerAlign}) => ({
          content: headerName ?? '',
          styles: headerAlign ? {halign: headerAlign} : undefined
        }))
      ],
      body: data.aggregatedEventStatisticsByItemPrice.aggregatedStatistics.map(
        (row) => [
          {content: row.ticketTypeName},
          {
            content: formatDecimal2Digits(row.itemPriceBeforeDiscount),
            styles: {halign: 'right'}
          },

          {content: row.discountName ?? ''},
          {
            content: discountValueFormatter(
              row.discountValue,
              row.discountType ?? undefined
            )
          },
          {
            content: formatDecimal2Digits(row.itemPrice),
            styles: {halign: 'right'}
          },
          {
            content: formatDecimal0Digits(row.saleTicketCountOnRetailChannel),
            styles: {halign: 'right'}
          },
          {
            content: formatDecimal2Digits(row.saleSumOnRetailChannel),
            styles: {halign: 'right'}
          },
          {
            content: formatDecimal0Digits(
              row.saleTicketCountOnEcommerceChannel
            ),
            styles: {halign: 'right'}
          },
          {
            content: formatDecimal2Digits(row.saleSumOnEcommerceChannel),
            styles: {halign: 'right'}
          },
          {
            content: formatDecimal0Digits(row.refundTicketCountOnRetailChannel),
            styles: {halign: 'right'}
          },
          {
            content: formatDecimal2Digits(row.refundSumOnRetailChannel),
            styles: {halign: 'right'}
          },
          {
            content: formatDecimal0Digits(
              row.refundTicketCountOnEcommerceChannel
            ),
            styles: {halign: 'right'}
          },
          {
            content: formatDecimal2Digits(row.refundSumOnEcommerceChannel),
            styles: {halign: 'right'}
          },
          {
            content: formatDecimal2Digits(row.profitTicketCount),
            styles: {halign: 'right'}
          },
          {
            content: formatDecimal2Digits(row.profit),
            styles: {halign: 'right'}
          }
        ]
      ),
      foot: [
        [
          {content: t('Summary'), colSpan: 5},
          {
            content: formatDecimal0Digits(
              data.aggregatedEventStatisticsByItemPrice
                .saleTicketCountOnRetailChannel
            ),
            styles: {halign: 'right'}
          },
          {
            content: formatDecimal2Digits(
              data.aggregatedEventStatisticsByItemPrice.saleSumOnRetailChannel
            ),
            styles: {halign: 'right'}
          },
          {
            content: formatDecimal0Digits(
              data.aggregatedEventStatisticsByItemPrice
                .saleTicketCountOnEcommerceChannel
            ),
            styles: {halign: 'right'}
          },
          {
            content: formatDecimal2Digits(
              data.aggregatedEventStatisticsByItemPrice
                .saleSumOnEcommerceChannel
            ),
            styles: {halign: 'right'}
          },
          {
            content: formatDecimal0Digits(
              data.aggregatedEventStatisticsByItemPrice
                .refundTicketCountOnRetailChannel
            ),
            styles: {halign: 'right'}
          },
          {
            content: formatDecimal2Digits(
              data.aggregatedEventStatisticsByItemPrice.refundSumOnRetailChannel
            ),
            styles: {halign: 'right'}
          },
          {
            content: formatDecimal0Digits(
              data.aggregatedEventStatisticsByItemPrice
                .refundTicketCountOnEcommerceChannel
            ),
            styles: {halign: 'right'}
          },
          {
            content: formatDecimal2Digits(
              data.aggregatedEventStatisticsByItemPrice
                .refundSumOnEcommerceChannel
            ),
            styles: {halign: 'right'}
          },
          {
            content: formatDecimal2Digits(
              data.aggregatedEventStatisticsByItemPrice.profitTicketCount
            ),
            styles: {halign: 'right'}
          },
          {
            content: formatDecimal2Digits(
              data.aggregatedEventStatisticsByItemPrice.profit
            ),
            styles: {halign: 'right'}
          }
        ]
      ]
    })
    if (iFrameRef?.current) {
      const pdfBlob = doc.output('blob')
      iFrameRef.current.src = URL.createObjectURL(pdfBlob)
      iFrameRef.current.onload = function () {
        iFrameRef.current?.contentWindow?.print()
      }
    } else {
      doc.save(
        `${t('event_sales_report_{{eventId}}', {
          eventId: data.aggregatedEventStatisticsByItemPrice.eventId
        })}.pdf`
      )
    }
  }
}
