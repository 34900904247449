import {useMutation, useQuery} from '@apollo/react-hooks'
import {FetchPolicy, WatchQueryFetchPolicy} from 'apollo-client'
import {DocumentNode} from 'graphql'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  AdmissionTypesForConditionResourceQuery,
  AdmissionTypesForConditionResourceQueryVariables,
  ApplicationRulePropertiesFragment,
  ApplicationRuleType,
  ConditionPropertiesFragment,
  CopyDiscountMutation,
  CopyDiscountMutationVariables,
  CreateApplicationRuleMutation,
  CreateApplicationRuleMutationVariables,
  CreateConditionInput,
  CreateConditionMutation,
  CreateConditionMutationVariables,
  CreateDiscountCodeMutation,
  CreateDiscountCodeMutationVariables,
  CreateDiscountCodesMutation,
  CreateDiscountCodesMutationVariables,
  CreateDiscountMutation,
  CreateDiscountMutationVariables,
  DeleteApplicationRuleMutation,
  DeleteApplicationRuleMutationVariables,
  DeleteConditionMutation,
  DeleteConditionMutationVariables,
  DeleteDiscountMutation,
  DeleteDiscountMutationVariables,
  DiscountCodesFilterInput,
  DiscountCodesQuery,
  DiscountCodesQueryVariables,
  DiscountInput,
  DiscountsQuery,
  DiscountsQueryVariables,
  GetDiscountQuery,
  GetDiscountQueryVariables,
  InvalidateDiscountCodesMutation,
  InvalidateDiscountCodesMutationVariables,
  LightweightClientShowQuery,
  LightweightClientShowQueryVariables,
  LightweightClientShowsQuery,
  LightweightClientShowsQueryVariables,
  LightweightEventQuery,
  LightweightEventQueryVariables,
  LightweightEventsQuery,
  LightweightEventsQueryVariables,
  ToursForConditionResourceQuery,
  ToursForConditionResourceQueryVariables,
  UpdateConditionInput,
  UpdateConditionMutation,
  UpdateConditionMutationVariables,
  UpdateDiscountInput,
  UpdateDiscountMutation,
  UpdateDiscountMutationVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'
import {TRANSLATED_LOCALES_FRAGMENT} from '../graphql'

const LIGHTWEIGHT_CLIENT_SHOW_PROPERTIES_FRAGMENT = gql`
  fragment LightweightClientShowProperties on ClientShow {
    id
    originalTitle
    translations {
      localeCode
      title
    }
  }
`

const LIGHTWEIGHT_EVENT_PROPERTIES_FRAGMENT = gql`
  fragment LightweightEventProperties on Event {
    id
    names {
      ...TranslatedLocales
    }
    startsAt
    formatCode
    versionCode
    soundMixCode
  }
  ${TRANSLATED_LOCALES_FRAGMENT}
`

const CONDITION_FRAGMENT = gql`
  fragment ConditionProperties on Condition {
    id
    applicationRuleId
    conditionData {
      operator
      value
    }
    resource
  }
`
const APPLICATION_RULE_FRAGMENT = gql`
  fragment ApplicationRuleProperties on ApplicationRule {
    id
    type
    conditions {
      ...ConditionProperties
    }
  }
  ${CONDITION_FRAGMENT}
`

export const DISCOUNT_PROPERTIES_FRAGMENT = gql`
  fragment DiscountProperties on Discount {
    id
    clientId
    name
    internalDescription
    application
    type
    value
    sellingChannels
    state
    applicationRulesCount
    divisions {
      id
      name
    }
    maxUsageLimitPerOrder
    displayMode
  }
`

const DISCOUNT_CODE_PROPERTIES_FRAGMENT = gql`
  fragment DiscountCodeProperties on DiscountCode {
    id
    name
    state
    usageLimit
    usageCount
    usageLimitPerOrder
    activationDate
    expirationDate
    createdByName
    createdAt
    updatedByName
    updatedAt
    description
    remainingRedemptionCount
    redemptionIntentCount
  }
`

const DISCOUNTS_QUERY = gql`
  query discounts(
    $filter: DiscountsFilterInput
    $paginationInput: PaginationInput
  ) {
    discounts(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...DiscountProperties
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
  ${DISCOUNT_PROPERTIES_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`

const DISCOUNT_QUERY = gql`
  query getDiscount($id: Int!) {
    discount(id: $id) {
      ...DiscountProperties
      applicationRules {
        ...ApplicationRuleProperties
      }
    }
  }
  ${APPLICATION_RULE_FRAGMENT}
  ${DISCOUNT_PROPERTIES_FRAGMENT}
`

const DISCOUNT_CODES_QUERY = gql`
  query discountCodes($discountId: Int!, $filter: DiscountCodesFilterInput) {
    discountCodes(discountId: $discountId, filter: $filter) {
      ...DiscountCodeProperties
    }
  }
  ${DISCOUNT_CODE_PROPERTIES_FRAGMENT}
`

const CREATE_DISCOUNT_MUTATION = gql`
  mutation createDiscount($input: DiscountInput!) {
    createDiscount(input: $input) {
      ...DiscountProperties
    }
  }
  ${DISCOUNT_PROPERTIES_FRAGMENT}
`
const INVALIDATE_DISCOUNT_CODES_MUTATION = gql`
  mutation invalidateDiscountCodes($ids: [Int!]!) {
    invalidateDiscountCodes(ids: $ids) {
      ...DiscountCodeProperties
    }
  }
  ${DISCOUNT_CODE_PROPERTIES_FRAGMENT}
`

const CREATE_DISCOUNT_CODE_MUTATION = gql`
  mutation createDiscountCode($input: DiscountCodeInput!, $name: String) {
    createDiscountCode(input: $input, name: $name) {
      ...DiscountCodeProperties
    }
  }
  ${DISCOUNT_CODE_PROPERTIES_FRAGMENT}
`
const CREATE_DISCOUNT_CODES_MUTATION = gql`
  mutation createDiscountCodes(
    $input: DiscountCodeInput!
    $count: PositiveInt!
  ) {
    createDiscountCodes(input: $input, count: $count) {
      ...DiscountCodeProperties
    }
  }
  ${DISCOUNT_CODE_PROPERTIES_FRAGMENT}
`

const COPY_DISCOUNT_MUTATION = gql`
  mutation copyDiscount($id: Int!, $input: DiscountInput!) {
    copyDiscount(id: $id, input: $input) {
      ...DiscountProperties
    }
  }
  ${DISCOUNT_PROPERTIES_FRAGMENT}
`

const UPDATE_DISCOUNT_MUTATION = gql`
  mutation updateDiscount($input: UpdateDiscountInput!) {
    updateDiscount(input: $input) {
      ...DiscountProperties
    }
  }
  ${DISCOUNT_PROPERTIES_FRAGMENT}
`

const DELETE_DISCOUNT_MUTATION = gql`
  mutation deleteDiscount($id: Int!) {
    deleteDiscount(id: $id) {
      id
    }
  }
`

const CREATE_APPLICATION_RULE_MUTATION = gql`
  mutation createApplicationRule($input: CreateApplicationRuleInput!) {
    createApplicationRule(input: $input) {
      ...ApplicationRuleProperties
    }
  }
  ${APPLICATION_RULE_FRAGMENT}
`

const DELETE_APPLICATION_RULE_MUTATION = gql`
  mutation deleteApplicationRule($id: Int!) {
    deleteApplicationRule(id: $id) {
      ...ApplicationRuleProperties
    }
  }
  ${APPLICATION_RULE_FRAGMENT}
`

const CREATE_CONDITION_MUTATION = gql`
  mutation createCondition($input: CreateConditionInput!) {
    createCondition(input: $input) {
      ...ConditionProperties
    }
  }
  ${CONDITION_FRAGMENT}
`

const UPDATE_CONDITION_MUTATION = gql`
  mutation updateCondition($input: UpdateConditionInput!) {
    updateCondition(input: $input) {
      ...ConditionProperties
    }
  }
  ${CONDITION_FRAGMENT}
`
const DELETE_CONDITION_MUTATION = gql`
  mutation deleteCondition($id: Int!) {
    deleteCondition(id: $id) {
      ...ConditionProperties
    }
  }
  ${CONDITION_FRAGMENT}
`

export const LIGHTWEIGHT_CLIENT_SHOWS_QUERY = gql`
  query lightweightClientShows(
    $filter: ShowsFilterInput
    $paginationInput: PaginationInput!
  ) {
    clientShows(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...LightweightClientShowProperties
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
  ${LIGHTWEIGHT_CLIENT_SHOW_PROPERTIES_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`

export const LIGHTWEIGHT_EVENTS_QUERY = gql`
  query lightweightEvents($filter: EventsFilterInput!) {
    events(filter: $filter) {
      ...LightweightEventProperties
    }
  }
  ${LIGHTWEIGHT_EVENT_PROPERTIES_FRAGMENT}
`

export const LIGHTWEIGHT_CLIENT_SHOW_QUERY = gql`
  query lightweightClientShow($id: Int!) {
    clientShow(id: $id) {
      ...LightweightClientShowProperties
    }
  }
  ${LIGHTWEIGHT_CLIENT_SHOW_PROPERTIES_FRAGMENT}
`

export const LIGHTWEIGHT_EVENT_QUERY = gql`
  query lightweightEvent($id: Int!) {
    event(id: $id) {
      ...LightweightEventProperties
    }
  }
  ${LIGHTWEIGHT_EVENT_PROPERTIES_FRAGMENT}
`

export const useDiscounts = (variables?: DiscountsQueryVariables) =>
  useQuery<DiscountsQuery, DiscountsQueryVariables>(DISCOUNTS_QUERY, {
    variables,
    fetchPolicy: 'network-only'
  })
export const useDiscountCodes = (
  discountId: number,
  filter?: DiscountCodesFilterInput
) =>
  useQuery<DiscountCodesQuery, DiscountCodesQueryVariables>(
    DISCOUNT_CODES_QUERY,
    {
      variables: {discountId, filter},
      fetchPolicy: 'network-only'
    }
  )

export const useCreateDiscountCode = (discountId: number) => {
  const [createDiscountCode] = useMutation<
    CreateDiscountCodeMutation,
    CreateDiscountCodeMutationVariables
  >(CREATE_DISCOUNT_CODE_MUTATION, {
    update(cache, {data}) {
      const createDiscountCode = data?.createDiscountCode
      const discountCodesQuery = cache.readQuery<DiscountCodesQuery>({
        query: DISCOUNT_CODES_QUERY,
        variables: {discountId}
      })

      if (discountCodesQuery && createDiscountCode) {
        cache.writeQuery({
          query: DISCOUNT_CODES_QUERY,
          variables: {discountId},
          data: {
            discountCodes: [
              createDiscountCode,
              ...discountCodesQuery?.discountCodes
            ]
          }
        })
      }
    }
  })
  return (variables: CreateDiscountCodeMutationVariables) =>
    createDiscountCode({
      variables
    })
}

export const useCreateDiscountCodes = (discountId: number) => {
  const [createDiscountCodes] = useMutation<
    CreateDiscountCodesMutation,
    CreateDiscountCodesMutationVariables
  >(CREATE_DISCOUNT_CODES_MUTATION, {
    update(cache, {data}) {
      const createDiscountCodes = data?.createDiscountCodes
      const discountCodesQuery = cache.readQuery<DiscountCodesQuery>({
        query: DISCOUNT_CODES_QUERY,
        variables: {discountId}
      })

      if (discountCodesQuery && createDiscountCodes) {
        cache.writeQuery({
          query: DISCOUNT_CODES_QUERY,
          variables: {discountId},
          data: {
            discountCodes: [
              ...createDiscountCodes,
              ...discountCodesQuery?.discountCodes
            ]
          }
        })
      }
    }
  })
  return (variables: CreateDiscountCodesMutationVariables) =>
    createDiscountCodes({
      variables
    })
}

export const useGetDiscount = (id: number) =>
  useQuery<GetDiscountQuery, GetDiscountQueryVariables>(DISCOUNT_QUERY, {
    variables: {
      id
    }
  })

export const useInvalidateDiscountCodes = () => {
  const [invalidateDiscountCodes] = useMutation<
    InvalidateDiscountCodesMutation,
    InvalidateDiscountCodesMutationVariables
  >(INVALIDATE_DISCOUNT_CODES_MUTATION)
  return useCallback(
    (ids: number[]) => invalidateDiscountCodes({variables: {ids}}),
    [invalidateDiscountCodes]
  )
}

export const useCreateDiscount = () => {
  const [createDiscount] = useMutation<
    CreateDiscountMutation,
    CreateDiscountMutationVariables
  >(CREATE_DISCOUNT_MUTATION, {
    refetchQueries: [
      {
        query: DISCOUNTS_QUERY,
        fetchPolicy: 'network-only'
      } as {
        query: DocumentNode
        fetchPolicy: FetchPolicy
      }
    ]
  })
  return useCallback(
    (input: DiscountInput) => createDiscount({variables: {input}}),
    [createDiscount]
  )
}

export const useUpdateDiscount = () => {
  const [updateDiscount] = useMutation<
    UpdateDiscountMutation,
    UpdateDiscountMutationVariables
  >(UPDATE_DISCOUNT_MUTATION, {
    refetchQueries: [
      {
        query: DISCOUNTS_QUERY,
        fetchPolicy: 'network-only'
      } as {
        query: DocumentNode
        fetchPolicy: FetchPolicy
      }
    ]
  })
  return useCallback(
    (input: UpdateDiscountInput) => updateDiscount({variables: {input}}),
    [updateDiscount]
  )
}

export const useCopyDiscount = () => {
  const [copyDiscount] = useMutation<
    CopyDiscountMutation,
    CopyDiscountMutationVariables
  >(COPY_DISCOUNT_MUTATION, {
    refetchQueries: [
      {
        query: DISCOUNTS_QUERY,
        fetchPolicy: 'network-only'
      } as {
        query: DocumentNode
        fetchPolicy: FetchPolicy
      }
    ]
  })
  return useCallback(
    (variables: CopyDiscountMutationVariables) => copyDiscount({variables}),
    [copyDiscount]
  )
}

export const useDeleteDiscount = () => {
  const [deleteDiscount] = useMutation<
    DeleteDiscountMutation,
    DeleteDiscountMutationVariables
  >(DELETE_DISCOUNT_MUTATION, {
    refetchQueries: [
      {
        query: DISCOUNTS_QUERY,
        fetchPolicy: 'network-only'
      } as {
        query: DocumentNode
        fetchPolicy: FetchPolicy
      }
    ]
  })
  return useCallback(
    (id: number) => deleteDiscount({variables: {id}}),
    [deleteDiscount]
  )
}

export const useCreateApplicationRuleWithCondition = (discountId: number) => {
  const [createApplicationRule] = useMutation<
    CreateApplicationRuleMutation,
    CreateApplicationRuleMutationVariables
  >(CREATE_APPLICATION_RULE_MUTATION)
  const [createCondition] = useMutation<
    CreateConditionMutation,
    CreateConditionMutationVariables
  >(CREATE_CONDITION_MUTATION, {
    refetchQueries: [
      {
        query: DISCOUNT_QUERY,
        fetchPolicy: 'network-only',
        variables: {id: discountId}
      } as {
        query: DocumentNode
        fetchPolicy: FetchPolicy
        variables: GetDiscountQueryVariables
      }
    ]
  })
  return useCallback(
    async (
      createConditionInput: Pick<
        CreateConditionInput,
        'value' | 'operator' | 'resource'
      >,
      type: ApplicationRuleType
    ): Promise<ApplicationRulePropertiesFragment> => {
      const applicationRuleResult = await createApplicationRule({
        variables: {
          input: {
            discountId,
            type
          }
        }
      })
      const conditionResult = await createCondition({
        variables: {
          input: {
            ...createConditionInput,
            applicationRuleId:
              applicationRuleResult.data!.createApplicationRule.id
          }
        }
      })
      return {
        ...applicationRuleResult.data!.createApplicationRule,
        conditions: [conditionResult.data!.createCondition]
      }
    },
    [createApplicationRule, createCondition, discountId]
  )
}

export const useCreateCondition = (discountId: number) => {
  const [createCondition] = useMutation<
    CreateConditionMutation,
    CreateConditionMutationVariables
  >(CREATE_CONDITION_MUTATION, {
    refetchQueries: [
      {
        query: DISCOUNT_QUERY,
        fetchPolicy: 'network-only',
        variables: {id: discountId}
      } as {
        query: DocumentNode
        fetchPolicy: FetchPolicy
        variables: GetDiscountQueryVariables
      }
    ]
  })
  return useCallback(
    async (
      input: CreateConditionInput
    ): Promise<ConditionPropertiesFragment> => {
      const result = await createCondition({
        variables: {
          input
        }
      })
      return result.data!.createCondition
    },
    [createCondition]
  )
}

export const useUpdateCondition = (discountId: number) => {
  const [updateCondition] = useMutation<
    UpdateConditionMutation,
    UpdateConditionMutationVariables
  >(UPDATE_CONDITION_MUTATION, {
    refetchQueries: [
      {
        query: DISCOUNT_QUERY,
        fetchPolicy: 'network-only',
        variables: {id: discountId}
      } as {
        query: DocumentNode
        fetchPolicy: FetchPolicy
        variables: GetDiscountQueryVariables
      }
    ]
  })
  return useCallback(
    async (
      input: UpdateConditionInput
    ): Promise<ConditionPropertiesFragment> => {
      const result = await updateCondition({
        variables: {
          input
        }
      })
      return result.data!.updateCondition
    },
    [updateCondition]
  )
}

export const useDeleteCondition = (discountId: number) => {
  const [deleteCondition] = useMutation<
    DeleteConditionMutation,
    DeleteConditionMutationVariables
  >(DELETE_CONDITION_MUTATION, {
    refetchQueries: [
      {
        query: DISCOUNT_QUERY,
        fetchPolicy: 'network-only',
        variables: {id: discountId}
      } as {
        query: DocumentNode
        fetchPolicy: FetchPolicy
        variables: GetDiscountQueryVariables
      }
    ]
  })
  return useCallback(
    async (id: number): Promise<ConditionPropertiesFragment> => {
      const result = await deleteCondition({
        variables: {
          id
        }
      })
      return result.data!.deleteCondition
    },
    [deleteCondition]
  )
}

export const useDeleteApplicationRule = (discountId: number) => {
  const [deleteApplicationRule] = useMutation<
    DeleteApplicationRuleMutation,
    DeleteApplicationRuleMutationVariables
  >(DELETE_APPLICATION_RULE_MUTATION, {
    refetchQueries: [
      {
        query: DISCOUNT_QUERY,
        fetchPolicy: 'network-only',
        variables: {id: discountId}
      } as {
        query: DocumentNode
        fetchPolicy: FetchPolicy
        variables: GetDiscountQueryVariables
      }
    ]
  })
  return useCallback(
    async (id: number): Promise<ApplicationRulePropertiesFragment> => {
      const applicationRuleResult = await deleteApplicationRule({
        variables: {
          id
        }
      })
      return applicationRuleResult.data!.deleteApplicationRule
    },
    [deleteApplicationRule]
  )
}

export const useLightweightClientShows = (text?: string) =>
  useQuery<LightweightClientShowsQuery, LightweightClientShowsQueryVariables>(
    LIGHTWEIGHT_CLIENT_SHOWS_QUERY,
    {
      variables: {
        filter: {
          hasText: text
        },
        paginationInput: {
          offset: 0,
          limit: 20
        }
      },
      fetchPolicy: 'network-only'
    }
  )

export const useLightweightEvents = (hasText?: string) =>
  useQuery<LightweightEventsQuery, LightweightEventsQueryVariables>(
    LIGHTWEIGHT_EVENTS_QUERY,
    {
      variables: {
        filter: {
          hasText
        }
      },
      fetchPolicy: 'network-only'
    }
  )

export const useLightweightClientShow = (id: number) =>
  useQuery<LightweightClientShowQuery, LightweightClientShowQueryVariables>(
    LIGHTWEIGHT_CLIENT_SHOW_QUERY,
    {
      variables: {
        id
      }
    }
  )

export const useLightweightEvent = (id: number) =>
  useQuery<LightweightEventQuery, LightweightEventQueryVariables>(
    LIGHTWEIGHT_EVENT_QUERY,
    {
      variables: {
        id
      }
    }
  )

const TOURS_FOR_CONDITION_RESOURCE = gql`
  ${PAGINATION_FRAGMENT}
  query ToursForConditionResource(
    $filter: ToursFilter
    $paginationInput: PaginationInput!
  ) {
    tours(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        name
        duration
        division {
          id
          name
        }
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useToursForConditionResource = (
  variables: ToursForConditionResourceQueryVariables,
  fetchPolicy?: WatchQueryFetchPolicy
) =>
  useQuery<
    ToursForConditionResourceQuery,
    ToursForConditionResourceQueryVariables
  >(TOURS_FOR_CONDITION_RESOURCE, {
    variables,
    fetchPolicy
  })

const ADMISSION_TYPES_FOR_CONDITION_RESOURCE = gql`
  ${PAGINATION_FRAGMENT}
  query AdmissionTypesForConditionResource(
    $filter: AdmissionTypesFilter
    $paginationInput: PaginationInput!
  ) {
    admissionTypes(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        name
        capacityDecreaseCount
        startingQuantity
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useAdmissionTypesForConditionResource = (
  variables: AdmissionTypesForConditionResourceQueryVariables,
  fetchPolicy?: WatchQueryFetchPolicy
) =>
  useQuery<
    AdmissionTypesForConditionResourceQuery,
    AdmissionTypesForConditionResourceQueryVariables
  >(ADMISSION_TYPES_FOR_CONDITION_RESOURCE, {
    variables,
    fetchPolicy
  })
