import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import DeleteIcon from '@mui/icons-material/Delete'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import {Box, Button, Divider, IconButton, SxProps} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {ApolloError} from 'apollo-client'
import cn from 'classnames'
import {sortBy} from 'lodash'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  EventSeatType,
  PermissionCode,
  ReservationDetailFragment,
  ReservationState,
  TicketItemPropertiesFragment,
  TourItemPropertiesFragment
} from '../../../../../__generated__/schema'
import {ReactComponent as ScissorsIcon} from '../../../../../assets/scissors.svg'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'
import {Tooltip} from '../../../../common'
import {ConfirmationDialog} from '../../../../common/ConfirmationDialog'
import {
  EmailDialog,
  EmailDialogField,
  IEmailDialogForm
} from '../../../../common/EmailDialog'
import {ListOfItemsSeparatedByDividers} from '../../../../common/ListOfItemsSeparatedByDividers'
import {Menu, MenuItem, useMenu} from '../../../../common/Menu'
import {Blank} from '../../../../visual/Blank'
import {useGetBasicTicketListItemProps} from '../../components/BasicTicketListItem'
import {TicketsSubtotal} from '../../components/TicketsSubtotal'
import {useSendReservationConfirmationEmail} from '../../graphql'
import {
  isTicketItemPropertiesFragment,
  isTourItemPropertiesFragment
} from '../../types'
import {useCurrentCart} from '../CurrentCartContext'
import {
  useAddReservationToCart,
  useCancelReservation,
  useRemoveReservationFromCart,
  useRemoveTicketItemsFromReservation,
  useRemoveTourItemFromReservation,
  useSplitReservation
} from '../graphql'
import {
  ReservationErrorDialog,
  ReservationErrorTriggers
} from '../ReservationErrorDialog'
import {TicketListItemWithRemoveButton} from '../TicketListItem'
import {SplitReservationDrawer} from './SplitReservationDrawer'
import {TourListItemWithRemoveButton} from './TourListItemWithRemoveButton'

interface ISubReservationProps {
  reservation: ReservationDetailFragment
  /**
   * @deprecated use sx prop
   */
  className?: string
  isReservationInCurrentCart: boolean
  refetch: () => void
  sx?: SxProps<Theme>
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto'
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  listDivider: {
    margin: theme.spacing(0, 2)
  },
  listItem: {
    padding: theme.spacing(0, 2, 0, 0.5)
  }
}))
export const SubReservation: React.FC<ISubReservationProps> = ({
  className,
  reservation,
  isReservationInCurrentCart,
  refetch,
  sx
}: ISubReservationProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const getBasicTicketListItemProps = useGetBasicTicketListItemProps()
  const {currentCartId, updateCurrentCart, initializeCurrentCart} =
    useCurrentCart()
  const addReservationToCart = useAddReservationToCart()
  const removeReservationFromCart = useRemoveReservationFromCart()
  const splitReservation = useSplitReservation()
  const history = useHistory()
  const {addInfoNotification, defaultErrorHandler, setShowBackdrop} =
    useMutationAssistanceHooks()

  const [reservationError, setReservationError] =
    useState<{
      error: ApolloError
      trigger: ReservationErrorTriggers
    } | null>(null)

  const handleAddReservationToCartButtonClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      const {data} = await addReservationToCart({
        reservationId: reservation.id,
        cartId: currentCartId
      })
      if (data?.addReservationToCart) {
        if (currentCartId) {
          updateCurrentCart()
        } else {
          initializeCurrentCart(data.addReservationToCart)
        }
      }
      addInfoNotification(t('Successfully added to cart'))
    } catch (e) {
      setReservationError({
        error: e,
        trigger: ReservationErrorTriggers.Add
      })
    } finally {
      setShowBackdrop(false)
    }
  }, [
    setShowBackdrop,
    addReservationToCart,
    reservation.id,
    currentCartId,
    addInfoNotification,
    t,
    updateCurrentCart,
    initializeCurrentCart
  ])
  const handleRemoveReservationFromCartButtonClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      const {data} = await removeReservationFromCart({
        reservationId: reservation.id,
        cartId: currentCartId!
      })
      if (data?.removeReservationFromCart) {
        updateCurrentCart()
      }
      addInfoNotification(t('Reservation removed from the cart'))
    } catch (e) {
      defaultErrorHandler(e, t('Removing reservation from the cart failed'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    setShowBackdrop,
    removeReservationFromCart,
    reservation.id,
    currentCartId,
    addInfoNotification,
    t,
    updateCurrentCart,
    defaultErrorHandler
  ])
  const ticketItems = (reservation?.items || [])
    .filter<TicketItemPropertiesFragment>(isTicketItemPropertiesFragment)
    .filter((item) => item.eventSeat?.event.id === reservation.event?.id)
  const tourItems = (reservation?.items || [])
    .filter<TourItemPropertiesFragment>(isTourItemPropertiesFragment)
    .filter((item) => item.tourTimeSlotId === reservation.tourTimeSlot?.id)
  const isEvent = ticketItems.length > 0
  const isReservationEmpty = ticketItems.length === 0 && tourItems.length === 0
  const {
    state: isDeleteReservationDialogOpen,
    setTrue: openDeleteReservationDialog,
    setFalse: closeDeleteReservationDialog
  } = useBooleanState(false)
  const {
    state: isSplitReservationDialogOpen,
    setTrue: openSplitReservationDialog,
    setFalse: closeSplitReservationDialog
  } = useBooleanState(false)

  const handleSplitReservationMenuItemClick = useCallback(() => {
    openSplitReservationDialog()
    closeMenu()
  }, [closeMenu, openSplitReservationDialog])

  const handleOnConfirmReservationErrorDialog = useCallback(() => {
    setReservationError(null)
    refetch()
  }, [refetch])

  const handleSplitReservation = useCallback(
    async (itemIds: number[]) => {
      try {
        setShowBackdrop(true)
        const {data} = await splitReservation({
          reservationId: reservation.id,
          cartId: currentCartId,
          itemIds
        })
        if (data?.splitReservation) {
          if (currentCartId) {
            updateCurrentCart()
          } else {
            initializeCurrentCart(data.splitReservation)
          }
          if (reservation.event) {
            history.replace(
              routeTo.admin.cashDesk.eventAuditoriumPreview(
                reservation.event.id
              )
            )
          }
          addInfoNotification(
            t('{{count}} item added to cart', {count: itemIds.length})
          )
        }
      } catch (e) {
        closeSplitReservationDialog()
        setReservationError({
          error: e,
          trigger: ReservationErrorTriggers.Split
        })
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      setShowBackdrop,
      splitReservation,
      reservation.id,
      reservation.event,
      currentCartId,
      addInfoNotification,
      t,
      updateCurrentCart,
      initializeCurrentCart,
      history,
      closeSplitReservationDialog
    ]
  )

  const cancelReservation = useCancelReservation()
  const handleDeleteReservationConfirmed = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await cancelReservation({reservationId: reservation.id})
      addInfoNotification(t('Reservation deleted'))
      history.replace(routeTo.admin.cashDesk.reservations())
      closeDeleteReservationDialog()
    } catch (e) {
      closeDeleteReservationDialog()
      setReservationError({error: e, trigger: ReservationErrorTriggers.Delete})
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    cancelReservation,
    closeDeleteReservationDialog,
    history,
    reservation.id,
    setShowBackdrop,
    t
  ])

  const {
    state: isSendReservationDialogOpen,
    setTrue: openSendReservationDialog,
    setFalse: closeSendReservationDialog
  } = useBooleanState(false)
  const handleSendReservationMenuItemClick = useCallback(() => {
    openSendReservationDialog()
    closeMenu()
  }, [closeMenu, openSendReservationDialog])

  const sendReservationConfirmationEmail = useSendReservationConfirmationEmail()
  const handleSendReservationDialogSubmit = useCallback(
    async (data: IEmailDialogForm) => {
      if (reservation.cart.uuid && reservation.cart.hash) {
        try {
          setShowBackdrop(true)
          await sendReservationConfirmationEmail({
            cartUUID: reservation.cart.uuid,
            cartHash: reservation.cart.hash,
            reservationIds: [reservation.id],
            deliverTo: [data[EmailDialogField.Email]]
          })
          addInfoNotification(
            t('Reservation was sent to {{email}}', {
              email: data[EmailDialogField.Email]
            })
          )
        } catch (error) {
          defaultErrorHandler(
            error,
            t('Error while sending reservation confirmation email')
          )
        } finally {
          setShowBackdrop(false)
          closeSendReservationDialog()
        }
      }
    },
    [
      addInfoNotification,
      closeSendReservationDialog,
      defaultErrorHandler,
      reservation.cart.hash,
      reservation.cart.uuid,
      reservation.id,
      sendReservationConfirmationEmail,
      setShowBackdrop,
      t
    ]
  )

  const removeTicketItemsFromReservation = useRemoveTicketItemsFromReservation()

  const createRemoveTicketItemHandler = useCallback(
    (itemId: number) => async () => {
      try {
        setShowBackdrop(true)
        await removeTicketItemsFromReservation(reservation.id, [itemId])
      } catch (err) {
        defaultErrorHandler(
          err,
          t('Error while removing ticket item from reservation.')
        )
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      defaultErrorHandler,
      removeTicketItemsFromReservation,
      reservation.id,
      setShowBackdrop,
      t
    ]
  )

  const removeTourItemFromReservation = useRemoveTourItemFromReservation()

  const createRemoveTourItemHandler = useCallback(
    (itemId: number) => async () => {
      try {
        setShowBackdrop(true)
        await removeTourItemFromReservation({
          reservationId: reservation.id,
          itemId
        })
      } catch (error) {
        defaultErrorHandler(
          error,
          t('Error while removing tour item from reservation.')
        )
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      defaultErrorHandler,
      removeTourItemFromReservation,
      reservation.id,
      setShowBackdrop,
      t
    ]
  )

  const handleDiscountsMenuItemClick = useCallback(() => {
    history.replace(
      routeTo.admin.cashDesk.reservationApplyDiscounts(reservation.id)
    )
  }, [history, reservation.id])
  const isEnabledPaymentForReservation = reservation.event
    ? reservation.event.salesAndReservation.pointOfSale
        .isEnabledPaymentForReservation
    : true
  return (
    <Box className={cn(classes.root, className)} sx={sx}>
      {reservationError &&
        reservationError.trigger === ReservationErrorTriggers.Split && (
          <ReservationErrorDialog
            error={reservationError.error}
            onConfirm={handleOnConfirmReservationErrorDialog}
            trigger={ReservationErrorTriggers.Split}
          />
        )}
      {reservationError &&
        reservationError.trigger === ReservationErrorTriggers.Delete && (
          <ReservationErrorDialog
            error={reservationError.error}
            onConfirm={handleOnConfirmReservationErrorDialog}
            trigger={ReservationErrorTriggers.Delete}
          />
        )}
      {reservationError &&
        reservationError.trigger === ReservationErrorTriggers.Add && (
          <ReservationErrorDialog
            error={reservationError.error}
            onConfirm={handleOnConfirmReservationErrorDialog}
            trigger={ReservationErrorTriggers.Add}
          />
        )}
      <div className={classes.header}>
        {reservation.state === ReservationState.Active &&
          P([PermissionCode.AddReservationToCart]) && (
            <Tooltip
              title={
                isEnabledPaymentForReservation
                  ? undefined
                  : t('Payment for this reservation is not allowed now.')
              }
            >
              <span>
                <Button
                  color="primary"
                  startIcon={<AddShoppingCartIcon />}
                  onClick={handleAddReservationToCartButtonClick}
                  disabled={
                    isReservationEmpty || !isEnabledPaymentForReservation
                  }
                >
                  {t('Add to cart')}
                </Button>
              </span>
            </Tooltip>
          )}
        {isReservationInCurrentCart &&
          P([PermissionCode.RemoveReservationFromCart]) && (
            <Button
              color="primary"
              startIcon={<RemoveShoppingCartIcon />}
              onClick={handleRemoveReservationFromCartButtonClick}
            >
              {t('Remove from cart')}
            </Button>
          )}
        <IconButton onClick={openMenu} edge="start">
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          onClose={closeMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <MenuItem
            label={t('Discounts')}
            onClick={handleDiscountsMenuItemClick}
            isDisabled={isReservationInCurrentCart || isReservationEmpty}
          />
          {reservation.event && (
            <MenuItem
              label={t('Split reservation')}
              isDisabled={isReservationInCurrentCart || isReservationEmpty}
              icon={<ScissorsIcon />}
              onClick={handleSplitReservationMenuItemClick}
              isShown={P([PermissionCode.SplitReservation])}
            />
          )}
          <MenuItem
            label={t('Send reservation')}
            isShown={P([PermissionCode.SendReservationConfirmationEmail])}
            icon={<SendOutlinedIcon />}
            onClick={handleSendReservationMenuItemClick}
            isDisabled={isReservationEmpty}
          />
          <Divider />
          <MenuItem
            label={t('Delete reservation')}
            isDisabled={isReservationInCurrentCart}
            icon={<DeleteIcon />}
            onClick={openDeleteReservationDialog}
            isShown={P([PermissionCode.CancelReservation])}
          />
        </Menu>
      </div>
      {ticketItems.length > 0 ? (
        <ListOfItemsSeparatedByDividers
          DividerProps={{
            className: classes.listDivider
          }}
        >
          {sortBy(ticketItems, 'id').map((ticketItem) => (
            <TicketListItemWithRemoveButton
              className={classes.listItem}
              {...getBasicTicketListItemProps(ticketItem)}
              key={ticketItem.id}
              onRemoveButtonClick={createRemoveTicketItemHandler(ticketItem.id)}
              isRemoveButtonDisabled={isReservationInCurrentCart}
              isRemoveButtonShown={
                ticketItem.eventSeat.type === EventSeatType.Seat
                  ? P([PermissionCode.RemoveTicketItemsFromReservation])
                  : ticketItem.eventSeat.type === EventSeatType.Zone
                  ? P([PermissionCode.RemoveZoneTicketItemsFromReservation])
                  : true
              }
            />
          ))}
        </ListOfItemsSeparatedByDividers>
      ) : tourItems.length > 0 ? (
        <ListOfItemsSeparatedByDividers
          DividerProps={{
            className: classes.listDivider
          }}
        >
          {sortBy(tourItems, 'id').map((tourItem) => (
            <TourListItemWithRemoveButton
              key={tourItem.id}
              label={tourItem.admissionType.name}
              price={translateEffectiveClientPrice(tourItem.price)}
              secondaryLabel={tourItem.appliedDiscounts
                .map((appliedDiscount) => appliedDiscount.discount.name)
                .join(' • ')}
              priceBeforeDiscountLabel={
                tourItem.price !== tourItem.priceBeforeDiscount
                  ? translateEffectiveClientPrice(tourItem.priceBeforeDiscount)
                  : undefined
              }
              onRemoveButtonClick={createRemoveTourItemHandler(tourItem.id)}
              isRemoveButtonHidden={
                !P([PermissionCode.RemoveTourItemFromReservation])
              }
              sx={{pr: 2, pl: 0.5}}
              isRemoveButtonDisabled={isReservationInCurrentCart}
            />
          ))}
        </ListOfItemsSeparatedByDividers>
      ) : (
        <Blank title={t('Add first item to reservation')} />
      )}
      <TicketsSubtotal
        items={isEvent ? ticketItems : tourItems}
        description={t('{{count}} ticket', {
          count: isEvent ? ticketItems.length : tourItems.length
        })}
      />
      <ConfirmationDialog
        title={t('Delete reservation?')}
        contentText={t(
          'Do you really want to delete this reservation? This action is permanent and irreversible.'
        )}
        confirmButtonLabel={t('Delete')}
        isOpen={isDeleteReservationDialogOpen}
        onCancel={closeDeleteReservationDialog}
        onConfirm={handleDeleteReservationConfirmed}
      />
      <SplitReservationDrawer
        isOpen={isSplitReservationDialogOpen}
        onClose={closeSplitReservationDialog}
        ticketItems={ticketItems}
        addReservationToCart={handleAddReservationToCartButtonClick}
        splitReservation={handleSplitReservation}
      />
      <EmailDialog
        isOpen={isSendReservationDialogOpen}
        onCancel={closeSendReservationDialog}
        onSubmit={handleSendReservationDialogSubmit}
        defaultValues={{
          [EmailDialogField.Email]: reservation.lead?.data.email || undefined
        }}
        inputLabel={t('Email address')}
        submitButtonLabel={t('Send')}
        submitButtonStartIcon={<SendOutlinedIcon />}
        title={t('Send reservation')}
      />
    </Box>
  )
}
