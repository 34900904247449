import {useTranslation} from 'react-i18next'
import {DiscountDisplayMode} from '../__generated__/schema'

export const useTranslateDiscountDisplayMode = () => {
  const {t} = useTranslation()
  const translated: Record<DiscountDisplayMode, string> = {
    [DiscountDisplayMode.Displayed]: t('DiscountDisplayMode->Displayed'),
    [DiscountDisplayMode.Hidden]: t('DiscountDisplayMode->Hidden')
  }
  return (mode: DiscountDisplayMode) => translated[mode]
}
