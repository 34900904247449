import {Drawer, MenuItem} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {
  DiscountDisplayMode,
  DiscountState,
  GetDiscountQuery,
  GetLightweightDivisionsQuery,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'

import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {
  DrawerTemplate,
  DrawerTemplateHeader,
  RenderOnData
} from '../../../../common'
import {CancelButton, SaveButton} from '../../../../common/Buttons'
import {SplitButton} from '../../../../visual'
import {useGetLightweightDivisions} from '../../graphql'
import {useShowFormStyles} from '../../shows/ShowForm'
import {DiscountForm} from '../DiscountForm'
import {useUpdateDiscount} from '../graphql'
import {
  DiscountFormField,
  IDiscountForm,
  transformDiscountIntoForm
} from '../types'

const EDIT_DISCOUNT_FORM_ID = 'editDiscountFormId'

const useStyles = makeStyles<Theme>(() => ({
  drawerPaper: {
    maxWidth: 560,
    width: '100%'
  }
}))

interface IEditDiscountDrawerProps {
  isOpen: boolean
  closeDrawer: () => void
  discount: GetDiscountQuery['discount']
}

export const EditDiscountDrawer: React.FC<IEditDiscountDrawerProps> = ({
  isOpen,
  closeDrawer,
  discount
}: IEditDiscountDrawerProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const classes = useStyles()
  const showFormClasses = useShowFormStyles()
  const {divisions, error, loading} = useGetLightweightDivisions()
  const updateDiscount = useUpdateDiscount()
  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()
  const currentState = useRef<DiscountState>(discount.state)
  const createSubmitButtonHandler = useCallback(
    (state: DiscountState) => () => {
      currentState.current = state
    },
    []
  )
  const handleSubmit = useCallback(
    async (form: IDiscountForm) => {
      setShowBackdrop(true)
      try {
        await updateDiscount({
          id: discount.id,
          name: form[DiscountFormField.NAME],
          internalDescription: form[DiscountFormField.INTERNAL_DESCRIPTION],
          sellingChannels: form[DiscountFormField.SELLING_CHANNELS],
          divisionIds: form[DiscountFormField.DIVISION_IDS],
          state: currentState.current,
          maxUsageLimitPerOrder:
            form[DiscountFormField.MAX_USAGE_LIMIT_PER_ORDER] || null,
          displayMode: form[DiscountFormField.DISPLAY_MODE]
            ? DiscountDisplayMode.Displayed
            : DiscountDisplayMode.Hidden
        })
        addInfoNotification(t('Discount updated'))
        closeDrawer()
      } catch (e) {
        defaultErrorHandler(e, t('Error while updating a discount'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      closeDrawer,
      defaultErrorHandler,
      discount.id,
      setShowBackdrop,
      t,
      updateDiscount
    ]
  )
  return (
    <Drawer
      open={isOpen}
      anchor="right"
      onClose={closeDrawer}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <RenderOnData
        data={divisions}
        error={error}
        loading={loading}
        errorMessage={t<string>('Loading divisions failed!')}
      >
        {(divisions: GetLightweightDivisionsQuery['divisions']) => (
          <DrawerTemplate
            header={
              <DrawerTemplateHeader
                onLeftActionClick={closeDrawer}
                title={t('Edit discount')}
              />
            }
            footer={
              <>
                <CancelButton onClick={closeDrawer} />
                {P([PermissionCode.UpdateDiscount]) && (
                  <SplitButton
                    Options={[
                      discount.state === DiscountState.Active ? (
                        <MenuItem
                          key="save-as-inactive"
                          type="submit"
                          form={EDIT_DISCOUNT_FORM_ID}
                          component="button"
                          onClick={createSubmitButtonHandler(
                            DiscountState.Inactive
                          )}
                        >
                          {t('Save as inactive')}
                        </MenuItem>
                      ) : (
                        <MenuItem
                          key="save-as-active"
                          type="submit"
                          form={EDIT_DISCOUNT_FORM_ID}
                          component="button"
                          onClick={createSubmitButtonHandler(
                            DiscountState.Active
                          )}
                        >
                          {t('Save as active')}
                        </MenuItem>
                      )
                    ]}
                  >
                    <SaveButton
                      type="submit"
                      form={EDIT_DISCOUNT_FORM_ID}
                      onClick={createSubmitButtonHandler(discount.state)}
                    />
                  </SplitButton>
                )}
              </>
            }
          >
            <DiscountForm
              formId={EDIT_DISCOUNT_FORM_ID}
              onSubmit={handleSubmit}
              className={showFormClasses.form}
              divisions={divisions}
              defaultValues={transformDiscountIntoForm(discount, divisions)}
              hideNonUpdatableFields
            />
          </DrawerTemplate>
        )}
      </RenderOnData>
    </Drawer>
  )
}
