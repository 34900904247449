import {ResourceLabelContentArg} from '@fullcalendar/resource'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {makeStyles} from '@mui/styles'
import dayjs from 'dayjs'
import React, {useCallback} from 'react'
import {Theme} from '../../../../../theme'
import {useResourceLabelContentState} from '../context/resourceLabelContentState'

import {ViewType} from './types'

const useStyles = makeStyles<Theme>(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }
}))

const ResourceLabelContentWithArrowDown: React.FC<ResourceLabelContentArg> = (
  args: ResourceLabelContentArg
) => {
  const classes = useStyles()

  const [, setResourceLabelContentState] = useResourceLabelContentState()

  const {
    resource: {id},
    view: {activeStart, activeEnd}
  } = args

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      const auditoriumId = parseInt(id, 10)
      const {clientX, clientY} = e
      setResourceLabelContentState((prevState) =>
        prevState?.auditoriumId === auditoriumId
          ? null
          : {
              auditoriumId,
              activeStart: dayjs(activeStart).toISOString(),
              activeEnd: dayjs(activeEnd).toISOString(),
              x: clientX,
              y: clientY
            }
      )
    },
    [activeEnd, activeStart, id, setResourceLabelContentState]
  )
  return (
    <div className={classes.root} onClick={handleClick}>
      {args.resource.extendedProps.name}
      <ArrowDropDownIcon />
    </div>
  )
}

export const ResourceLabelContent: React.FC<ResourceLabelContentArg> = (
  args: ResourceLabelContentArg
) => {
  const {
    view: {type}
  } = args
  return [ViewType.Week, ViewType.Day].includes(type as ViewType) ? (
    <ResourceLabelContentWithArrowDown {...args} />
  ) : (
    <span>{args.resource.extendedProps.name}</span>
  )
}
