type Config = {
  characters: string
  base: number
}
export const encode = (id: number, {characters, base}: Config): string => {
  if (id === 0) {
    return characters[0]
  }

  let encodedString = ''

  while (id > 0) {
    encodedString = characters[id % base] + encodedString
    id = Math.floor(id / base)
  }

  return encodedString
}

export const decode = (
  compressedId: string,
  {characters, base}: Config
): number => {
  let id = 0

  for (let i = 0; i < compressedId.length; i++) {
    const char = compressedId[i]
    const charValue = characters.indexOf(char)
    id = id * base + charValue
  }

  return id
}
export const getLastCompressedId = (compressedIds: string[]): string | null => {
  let currentLastCompressedId = compressedIds[0] ?? null

  for (const compressedId of compressedIds) {
    if (compressedId.length > currentLastCompressedId.length) {
      currentLastCompressedId = compressedId
    } else {
      if (compressedId > currentLastCompressedId) {
        currentLastCompressedId = compressedId
      }
    }
  }

  return currentLastCompressedId
}

const getCompressedIdGenerator = (
  config: Config
): {
  getCompressedId: () => string
  resetGenerator: () => void
  restoreGenerator: (encodedStrings: string[]) => void
} => {
  let counter = 0

  return {
    getCompressedId: () => encode(counter++, config),
    resetGenerator: () => {
      counter = 0
    },
    restoreGenerator: (encodedStrings: string[]) => {
      const lastCompressedId = getLastCompressedId(encodedStrings)
      counter =
        lastCompressedId !== null ? decode(lastCompressedId, config) + 1 : 0
    }
  }
}

const characters =
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'

export const {getCompressedId, resetGenerator, restoreGenerator} =
  getCompressedIdGenerator({
    characters,
    base: characters.length
  })
