import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {ClaimQuery, ClaimState} from '../../../../__generated__/schema'
import {useClaimDetailParams} from '../../../../utils/pathname'
import {routeTo} from '../../../../utils/routes'
import {RenderOnData} from '../../../common'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {SecondaryHeader} from '../Header'
import {ChildrenOnValidClaim} from './ChildrenOnValidClaim'
import {ClaimSummary} from './ClaimSummary'
import {useClaim} from './graphql'
import {PendingClaimCore} from './PendingClaimCore'
import {RefundClaimCore} from './RefundClaimCore'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  grid: {
    display: 'grid',
    height: '100%',
    overflow: 'hidden',
    gridTemplateColumns: '360px 1fr'
  },
  refundClaimCore: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  }
}))

export const RefundClaim: React.FC = () => {
  const {t} = useTranslation()
  const {claimId} = useClaimDetailParams()
  const {data, loading, error, refetch} = useClaim(claimId)
  const classes = useStyles()
  const history = useHistory()
  const handleBackClick = useCallback(
    () => history.push(routeTo.admin.claims.detail(claimId)),
    [claimId, history]
  )
  return (
    <PageWithHeaderTemplate
      header={
        <SecondaryHeader
          title={t('Refund claim')}
          onLeftActionClick={handleBackClick}
          isDatetimeEnabled
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <RenderOnData
          data={data}
          error={error}
          loading={loading}
          errorMessage={t<string>('Error while loading claim')}
          dataCondition={(data: ClaimQuery) => Boolean(data.claim)}
        >
          {(data: ClaimQuery) => (
            <ChildrenOnValidClaim claim={data.claim}>
              <div className={classes.root}>
                <div className={classes.grid}>
                  <ClaimSummary claim={data.claim} />
                  {data.claim.state === ClaimState.Pending ? (
                    <PendingClaimCore claim={data.claim} refetch={refetch} />
                  ) : (
                    <RefundClaimCore
                      className={classes.refundClaimCore}
                      claim={data.claim}
                    />
                  )}
                </div>
              </div>
            </ChildrenOnValidClaim>
          )}
        </RenderOnData>
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
