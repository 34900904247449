import {useLazyQuery} from '@apollo/react-hooks'
import {CalendarApi} from '@fullcalendar/core'
import {Drawer} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  DefaultDivisionForAuditoriumQuery,
  DefaultDivisionForAuditoriumQueryVariables,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useClientLocaleCode} from '../../../../../hooks/getLocales'
import {useRequireEffectiveClient} from '../../../../../hooks/requireEffectiveClient'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {convertValueToFloat} from '../../../../../utils/conversions'

import {useDefaultErrorHandler} from '../../../../../utils/errors'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../common'
import {CancelButton, SaveButton} from '../../../../common/Buttons'
import {useBackdropState} from '../../../../context/backdrop'
import {useNotifications} from '../../../../context/notifications'
import {Error} from '../../../../visual'
import {useCountryVatRate} from '../../graphql'
import {useAddEventProperties} from '../context/addEventProperties'
import {DEFAULT_DIVISION_FOR_AUDITORIUM, useCreateEvent} from '../graphql'
import {CREATE_EVENT_FORM_ID, CreateEventForm} from './CreateEventForm'
import {useActiveDivisions, useSelectableAuditoriumLayouts} from './dataUtilts'
import {
  CreateEventFormField,
  ICreateEventForm,
  TicketTypeFormField,
  useGetCreateEventFormDefaults
} from './types'

interface ICreateEventDrawerProps {
  api: CalendarApi
  venueId: number
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    maxWidth: 728,
    width: '100%'
  },
  form: {
    padding: theme.spacing(3)
  }
}))

const useErrorMessage = ({
  divisionItemsLength,
  auditoriumLayoutItemsLength
}: {
  divisionItemsLength: number
  auditoriumLayoutItemsLength: number
}) => {
  const {t} = useTranslation()
  if (divisionItemsLength === 0) {
    return t('No active divisions available')
  }
  if (auditoriumLayoutItemsLength === 0) {
    return t('No selectable auditorium layouts available')
  }
  return null
}

export const CreateEventDrawer: React.FC<ICreateEventDrawerProps> = ({
  api,
  venueId
}: ICreateEventDrawerProps) => {
  const {t} = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [defaultDivisionId, setDefaultDivisionId] =
    useState<number | undefined>(undefined)
  const {ticketDefaultVatRate, VATRegistered, countryCode} =
    useRequireEffectiveClient()
  const {data: countryVatRatesData} = useCountryVatRate(countryCode)
  const classes = useStyles()
  const [addEventProperties, setAddEventProperties] = useAddEventProperties()
  const [getDefaultDivision] = useLazyQuery<
    DefaultDivisionForAuditoriumQuery,
    DefaultDivisionForAuditoriumQueryVariables
  >(DEFAULT_DIVISION_FOR_AUDITORIUM, {
    onCompleted: (data) =>
      setDefaultDivisionId(data.auditorium.defaultDivision?.id),
    fetchPolicy: 'network-only'
  })
  const handleClose = useCallback(() => {
    setIsOpen(false)
    setDefaultDivisionId(undefined)
  }, [])
  useEffect(() => {
    if (addEventProperties) {
      getDefaultDivision({variables: {id: addEventProperties.auditoriumId}})
      setIsOpen(true)
    }
  }, [addEventProperties, getDefaultDivision])

  const divisions = useActiveDivisions()
  const auditoriumLayouts = useSelectableAuditoriumLayouts({
    venueId,
    auditoriumId: addEventProperties?.auditoriumId || -1
  })
  const defaultValues = useGetCreateEventFormDefaults({
    addEventProperties,
    divisions,
    auditoriumLayouts,
    ticketDefaultVatRate: ticketDefaultVatRate || 0,
    defaultDivisionId
  })
  const {setShowBackdrop} = useBackdropState()
  const clientLocaleCode = useClientLocaleCode()
  const defaultErrorHandler = useDefaultErrorHandler()
  const {addInfoNotification} = useNotifications()
  const createEvent = useCreateEvent()
  const {P} = useEnsurePermissions()

  const onSubmit = useCallback(
    async (data: ICreateEventForm) => {
      if (!addEventProperties) return
      try {
        setShowBackdrop(true)
        await createEvent({
          data: {
            startsAt: data.startsAt.toISOString(),
            auditoriumLayoutPricingId: data.auditoriumLayoutPricingId!,
            divisionId: data.divisionId,
            // TODO: make those fields optional (needs backend changes)
            names: {
              en: '',
              cs: '',
              sk: '',
              hu: '',
              [clientLocaleCode]: data.name
            },
            showId: addEventProperties.showId,
            duration: addEventProperties.duration,
            serviceTime: data[CreateEventFormField.SERVICE_TIME] ?? 0,
            versionCode: addEventProperties.versionCode,
            formatCode: addEventProperties.formatCode,
            soundMixCode: addEventProperties.soundMixCode,
            ageClassificationCode: addEventProperties!.ageClassificationCode,
            ticketTypes: Object.values(data.ticketTypes).map((ticketType) => ({
              ...ticketType,
              price: convertValueToFloat(ticketType.price) || 0,
              ticketTypeId: parseInt(String(ticketType.ticketTypeId), 10),
              vatRate: ticketType[TicketTypeFormField.VAT_RATE]
            }))
          }
        })
        handleClose()
        addInfoNotification(t('Event created'))
      } catch (err) {
        defaultErrorHandler(err, t('Error while creating event.'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addEventProperties,
      addInfoNotification,
      clientLocaleCode,
      createEvent,
      defaultErrorHandler,
      handleClose,
      setShowBackdrop,
      t
    ]
  )
  const handleExited = useCallback(() => {
    const events = api.getEvents()
    const eventToRemove = events.find(
      (e) => e.extendedProps.id === addEventProperties?.calendarEventId
    )
    eventToRemove && eventToRemove.remove()
    setAddEventProperties(null)
  }, [addEventProperties?.calendarEventId, api, setAddEventProperties])
  const errorMessage = useErrorMessage({
    divisionItemsLength: divisions.length,
    auditoriumLayoutItemsLength: auditoriumLayouts.length
  })
  return (
    <Drawer
      open={isOpen}
      onClose={handleClose}
      classes={{
        paper: classes.root
      }}
      anchor="right"
      SlideProps={{
        onExited: handleExited
      }}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            title={t('Create event')}
            onLeftActionClick={handleClose}
          />
        }
        footer={
          <>
            <CancelButton onClick={handleClose} />
            {P([PermissionCode.CreateEvent]) && (
              <SaveButton
                type="submit"
                form={CREATE_EVENT_FORM_ID}
                disabled={!!errorMessage}
              >
                {t('Create')}
              </SaveButton>
            )}
          </>
        }
      >
        {errorMessage ? (
          <Error error={null} message={errorMessage} />
        ) : (
          <CreateEventForm
            className={classes.form}
            defaultValues={defaultValues}
            divisions={divisions}
            auditoriumLayouts={auditoriumLayouts}
            onSubmit={onSubmit}
            ticketDefaultVatRate={ticketDefaultVatRate || 0}
            clientVatRegistered={VATRegistered}
            countryVatRates={countryVatRatesData?.countryVatRates || []}
          />
        )}
      </DrawerTemplate>
    </Drawer>
  )
}
