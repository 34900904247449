import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useEffect, useRef} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  DiscountApplication,
  GetLightweightDivisionsQuery,
  SellingChannel
} from '../../../../__generated__/schema'
import {useTranslateSellingChannel} from '../../../../hooks/sellingChannel'
import {Theme} from '../../../../theme'
import {
  useIsFormMultiSelectRequired,
  useIsPositiveInteger,
  useIsStringWithMaxLength
} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {ControlledFormCheckboxGroup} from '../../../form/ControlledFormCheckboxGroup'
import {FormSwitchWithLabels} from '../../../form/FormSwitchWithLabels'

import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {ApplicationRadioGroup} from './ApplicationRadioGroup'
import {TypeRadioGroup} from './TypeRadioGroup'
import {DiscountFormField, IDiscountForm} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    display: 'grid',
    gridAutoFlow: 'row',
    padding: theme.spacing(3)
  },
  displayNone: {
    display: 'none'
  }
}))

interface IDiscountFormProps {
  defaultValues?: Partial<IDiscountForm>
  formId: string
  className?: string
  onSubmit: (data: IDiscountForm) => void
  divisions: GetLightweightDivisionsQuery['divisions']
  disabledFields?: DiscountFormField[]
  hideNonUpdatableFields?: boolean
}

const parseId = (checkboxValue: string) => parseInt(checkboxValue, 10)
const identity = (sellingChannel: string) => sellingChannel

export const DiscountForm: React.FC<IDiscountFormProps> = ({
  defaultValues = {},
  formId,
  className,
  onSubmit,
  divisions,
  hideNonUpdatableFields
}: IDiscountFormProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {
    register,
    unregister,
    setValue,
    errors,
    watch,
    handleSubmit,
    control,
    triggerValidation
  } = useForm<IDiscountForm>({
    defaultValues
  })

  const translateSellingChannel = useTranslateSellingChannel()
  const isFormMultiSelectRequired = useIsFormMultiSelectRequired()
  const isStringWithMaxLength = useIsStringWithMaxLength(255)
  const isPositiveInteger = useIsPositiveInteger()

  const isMountingRef = useRef<boolean>(false)
  const selectedApplication = watch(DiscountFormField.APPLICATION)
  useEffect(() => {
    isMountingRef.current = true
  }, [])
  useEffect(() => {
    if (!isMountingRef.current) {
      if (
        [DiscountApplication.Selectable, DiscountApplication.Customer].includes(
          selectedApplication
        )
      ) {
        setValue(DiscountFormField.DISPLAY_MODE, true)
      } else {
        setValue(DiscountFormField.DISPLAY_MODE, false)
      }
    } else {
      isMountingRef.current = false
    }
  }, [selectedApplication, setValue])
  return (
    <form
      id={formId}
      className={cn(className, classes.form)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IDiscountForm>
            register={register}
            name={DiscountFormField.NAME}
            key={DiscountFormField.NAME}
            errors={errors}
            setValue={setValue}
            triggerValidation={triggerValidation}
            watch={watch}
            label={t('Name')}
            validationOptions={{
              required: true,
              validate: isStringWithMaxLength
            }}
            required
            fullWidth
            helperNote={t('This will appear to the customer.')}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IDiscountForm>
            register={register}
            name={DiscountFormField.INTERNAL_DESCRIPTION}
            key={DiscountFormField.INTERNAL_DESCRIPTION}
            errors={errors}
            setValue={setValue}
            triggerValidation={triggerValidation}
            watch={watch}
            label={t('Internal description')}
            validationOptions={{
              validate: isStringWithMaxLength
            }}
            fullWidth
            multiline
            rows={3}
            helperNote={t('Optional. Customer will never see this.')}
          />
        ]}
      />
      <div className={cn({[classes.displayNone]: hideNonUpdatableFields})}>
        <InputRow
          nodes={[
            <ApplicationRadioGroup
              key={DiscountFormField.APPLICATION}
              defaultValue={defaultValues[DiscountFormField.APPLICATION]}
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
            />
          ]}
        />
        <InputRow
          nodes={[
            <TypeRadioGroup
              defaultValues={defaultValues}
              key={DiscountFormField.TYPE}
              register={register}
              setValue={setValue}
              watch={watch}
              triggerValidation={triggerValidation}
              errors={errors}
            />
          ]}
        />
      </div>
      <InputRow
        nodes={[
          <div key={DiscountFormField.DIVISION_IDS}>
            <ControlledFormCheckboxGroup<IDiscountForm, number>
              label={t('Allowed divisions')}
              errors={errors}
              name={DiscountFormField.DIVISION_IDS}
              options={divisions}
              register={register}
              unregister={unregister}
              setValue={setValue}
              watch={watch}
              validationOptions={{
                validate: isFormMultiSelectRequired
              }}
              required
              parseCheckboxValue={parseId}
              helperNote={t('Available for orders of specified division.')}
            />
          </div>
        ]}
      />
      <InputRow
        nodes={[
          <div key={DiscountFormField.SELLING_CHANNELS}>
            <ControlledFormCheckboxGroup<IDiscountForm, string>
              label={t('Allowed selling channels')}
              errors={errors}
              name={DiscountFormField.SELLING_CHANNELS}
              parseCheckboxValue={identity}
              options={[
                {
                  name: translateSellingChannel(SellingChannel.Retail),
                  id: SellingChannel.Retail
                },
                {
                  name: translateSellingChannel(SellingChannel.ECommerce),
                  id: SellingChannel.ECommerce
                }
              ]}
              register={register}
              unregister={unregister}
              setValue={setValue}
              watch={watch}
              validationOptions={{
                validate: isFormMultiSelectRequired
              }}
              required
              helperNote={t(
                'Available for orders created on specified selling channel.'
              )}
            />
          </div>
        ]}
      />
      <InputRow
        nodes={[
          <FormSwitchWithLabels<IDiscountForm>
            name={DiscountFormField.DISPLAY_MODE}
            key={DiscountFormField.DISPLAY_MODE}
            primaryLabel={t('Display discount for customer?')}
            control={control}
            watch={watch}
            defaultValue={defaultValues?.displayMode || false}
            disabled={selectedApplication !== DiscountApplication.Code}
            secondaryLabel={t(
              "When set to 'Yes,' the discount will appear in the list of discounts. It is recommended to select 'No' for discount codes."
            )}
            enabledText=""
            disabledText=""
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IDiscountForm>
            register={register}
            name={DiscountFormField.MAX_USAGE_LIMIT_PER_ORDER}
            key={DiscountFormField.MAX_USAGE_LIMIT_PER_ORDER}
            errors={errors}
            setValue={setValue}
            triggerValidation={triggerValidation}
            watch={watch}
            label={t('Max usage limit per order')}
            validationOptions={{
              validate: isPositiveInteger
            }}
            fullWidth
            helperNote={t(
              'Specifies the maximum number of times a discount can be applied per order to prevent exceeding the usage limit.'
            )}
          />
        ]}
      />
    </form>
  )
}
