import {DayHeaderContentArg} from '@fullcalendar/core'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import dayjs from 'dayjs'
import React, {useCallback} from 'react'

import {Theme} from '../../../../../theme'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {useDayHeaderContentState} from '../context/dayHeaderContentState'
import {ViewType} from './types'

export const FUTURE_BG_COLOR = '#ffffff'
export const PAST_BG_COLOR = '#fafafa'

enum TimeVariant {
  Today = 'today',
  Past = 'past',
  Future = 'future'
}

const getDayHeaderTextColor = (variant: TimeVariant, theme: Theme) =>
  variant === TimeVariant.Past
    ? theme.palette.text.secondary
    : variant === TimeVariant.Today
    ? theme.palette.primary.main
    : theme.palette.text.primary

const useDateHeaderStyles = makeStyles<Theme, {variant: TimeVariant}>(
  (theme) => ({
    root: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      gridTemplateColumns: 'auto auto',
      gridTemplateAreas: `
        "weekDay arrow"
        "monthDay arrow"
      `,
      gap: theme.spacing(0, 1.5),
      cursor: 'pointer',
      color: ({variant}) => getDayHeaderTextColor(variant, theme),
      fill: ({variant}) => getDayHeaderTextColor(variant, theme)
    },
    weekDay: {
      gridArea: 'weekDay',
      lineHeight: 'initial'
    },
    monthDay: {
      gridArea: 'monthDay'
    },
    arrow: {
      gridArea: 'arrow',
      alignSelf: 'center'
    }
  })
)

const WeekViewDateHeaderContent: React.FC<DayHeaderContentArg> = (
  dayHeaderContentArg: DayHeaderContentArg
) => {
  const date = dayjs(dayHeaderContentArg.date).toDate()
  const [dayHeaderContentState, setDayHeaderContentState] =
    useDayHeaderContentState()

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      const isoDate = date.toISOString()
      const auditoriumId = parseInt(dayHeaderContentArg.resource?.id, 10)
      const {clientX, clientY} = e

      setDayHeaderContentState((previousState) => ({
        ...previousState,
        clickInfo:
          dayHeaderContentState.clickInfo?.auditoriumId === auditoriumId &&
          dayHeaderContentState.clickInfo?.date === isoDate
            ? null
            : {
                x: clientX,
                y: clientY,
                auditoriumId,
                date: isoDate
              }
      }))
    },
    [
      date,
      dayHeaderContentArg.resource?.id,
      dayHeaderContentState.clickInfo?.auditoriumId,
      dayHeaderContentState.clickInfo?.date,
      setDayHeaderContentState
    ]
  )

  const roundedDate = dayjs(date).startOf('day')
  const roundedNow = dayjs().startOf('day')

  const variant: TimeVariant = roundedDate.isSame(roundedNow)
    ? TimeVariant.Today
    : roundedDate.isAfter(roundedNow)
    ? TimeVariant.Future
    : TimeVariant.Past

  const classes = useDateHeaderStyles({variant})
  const {formatWeekDay} = useDateTimeFormatters()
  return (
    <div className={classes.root} onClick={handleClick}>
      <Typography variant="overline" className={classes.weekDay}>
        {formatWeekDay(date)}
      </Typography>
      <Typography variant="h6" className={classes.monthDay}>
        {dayjs(date).get('date')}
      </Typography>
      <ArrowDropDownIcon className={classes.arrow} />
    </div>
  )
}

export const DateHeaderContent: React.FC<DayHeaderContentArg> = (
  dayHeaderContentArgs: DayHeaderContentArg
) => {
  return dayHeaderContentArgs.view.type !== ViewType.Week ? (
    <span>{dayHeaderContentArgs.text}</span>
  ) : (
    <WeekViewDateHeaderContent {...dayHeaderContentArgs} />
  )
}
