import {List} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  DetailEventPropertiesFragment,
  SellingChannel
} from '../../../../../../__generated__/schema'
import {Theme} from '../../../../../../theme'
import {routeTo} from '../../../../../../utils/routes'

import {StackableCardDivider, StackableCardWrapper} from '../../../../../visual'
import {useGetPriceSecondaryText} from '../../utils'

import {NavigatableListItem} from './common'

const useStyles = makeStyles<Theme>(() => ({
  list: {
    padding: 0
  }
}))

interface IPricingAndAvailabilityProps {
  event: DetailEventPropertiesFragment
}

export const PricingAndAvailability: React.FC<IPricingAndAvailabilityProps> = ({
  event
}: IPricingAndAvailabilityProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const history = useHistory()

  const onPricesClick = () =>
    history.push(routeTo.admin.events.editPricingOptions(event.id))

  const onSalesClick = () =>
    history.push(routeTo.admin.events.editSaleOptions(event.id))

  const onSeatsClick = () =>
    window.open(routeTo.admin.seatOptions(event.id), 'blank')

  const onDiscountsClick = useCallback(() => {
    history.push(routeTo.admin.events.eventDiscountDetails(event.id))
  }, [event.id, history])

  const formatPriceSecondaryText = useGetPriceSecondaryText()

  const getDiscountStatus = (
    allowedDiscountsSellingChannels: SellingChannel[]
  ) => {
    return [
      allowedDiscountsSellingChannels.includes(SellingChannel.Retail)
        ? t('Enabled for retail')
        : t('Disabled for retail'),
      allowedDiscountsSellingChannels.includes(SellingChannel.ECommerce)
        ? t('Enabled for ecommerce')
        : t('Disabled for ecommerce')
    ].join(' • ')
  }

  return (
    <StackableCardWrapper>
      <List component="nav" className={classes.list}>
        <NavigatableListItem
          mainLabel={t('Prices')}
          onClick={onPricesClick}
          secondaryLabel={formatPriceSecondaryText({
            pricings: event.pricings,
            activePricing: event.activePricing
          })}
        />
        <StackableCardDivider />
        <NavigatableListItem
          mainLabel={t('Promotions')}
          onClick={onDiscountsClick}
          secondaryLabel={getDiscountStatus(
            event.allowedDiscountsSellingChannels
          )}
        />
        <StackableCardDivider />
        <NavigatableListItem
          mainLabel={t('Sale and reservation options')}
          onClick={onSalesClick}
        />
        <StackableCardDivider />
        <NavigatableListItem
          mainLabel={t('Layout pricing and seat states')}
          onClick={onSeatsClick}
        />
      </List>
    </StackableCardWrapper>
  )
}
